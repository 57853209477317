<template>
	<section class="main-container">
		<h1>Toutes les immatriculations</h1>

		<form>
			<div class="row">
				<div class="form-group col-3">
					<label for="number">N° d'inscription</label>
					<input type="text" name="number" id="number" class="form-control" v-model="search.number">
				</div>
				<div class="form-group col-3">
					<label for="name">Nom & Prénoms</label>
					<input type="text" name="name" id="name" class="form-control" v-model="search.name">
				</div>
				<div class="form-group col-3">
					<label for="birthdate">Date de naissance</label>
					<input type="date" name="birthdate" id="birthdate" class="form-control" v-model="search.birthdate">
				</div>
				<div class="form-group col-3">
					<label for="birthplace">Lieu de naissance</label>
					<input type="text" name="birthplace" id="birthplace" class="form-control" v-model="search.birthplace">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-3">
					<label for="phone">Téléphone</label>
					<input type="text" name="phone" id="phone" class="form-control" v-model="search.phone">
				</div>
				<div class="form-group col-3">
					<label for="email">Email</label>
					<input type="text" name="email" id="email" class="form-control" v-model="search.email">
				</div>
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="getPersons()">
						Rechercher
					</button>
				</div>
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-outline-primary" @click.prevent="resetSearch()">
						Réinitialiser
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>

		<data-table v-bind="parameters"/>
	</section>
</template>

<script>

import axios from 'axios'
//import persons from './persons'
import ActionsButtons from '@/components/ImmatActionsButtons'
import CreatedDateInDatatable from '@/components/CreatedDateInDatatable'
import BirthdateInDatatable from '@/components/BirthdateInDatatable'
//import NameInDatatable from '@/components/NameInDatatable'
//import ContactsInDatatable from '@/components/ContactsInDatatable'
export default {
	name: 'List',
	components: { },
	data () {
		return {
			persons: [],
			search: {
				number: "",
				name: "",
				phone: "",
				email: "",
				birthdate: "",
				birthplace: ""
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre d'individus par page :entries",
					infoText: "Vue de :first à :last sur :total individus",
					infoTextFiltered: "Vue de :first à :last sur :filtered (résultats de :total individus)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche:",
					downloadText: "Exporter en:",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 10,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				data: this.persons,
				columns: [
					{
						key: "registration_number",
						title: "N°"
					},
					{
						key: "lastname",
						title: "Nom"
					},
					{
						key: "firstname",
						title: "Prénom"
					},
					{
						key: "birthday",
						title: "Date Nais.",
						component: BirthdateInDatatable
					},
					{
						key: "birthplace",
						title: "Lieu nais."
					},
					/*{
						key: "email",
						title: "Contacts",
						component: ContactsInDatatable
					},*/
					{
						key: "created_at.date",
						title: "Inscrit(e) le",
						component: CreatedDateInDatatable
					},
					{
						key: "actions",
						sortable: false,
						component: ActionsButtons
					}
				]
			}
		}
    },
	methods: {
		resetSearch(){
			this.search= {
				number: "",
				name: "",
				phone: "",
				email: "",
				birthdate: "",
				birthplace: ""
			}
			this.getPersons()
		},
		getPersons () {
			let vm = this

			let datas = ""

			if(this.search.number != "")
				datas += "number="+this.search.number

			if(this.search.name != "")
				datas += "&name="+this.search.name

			if(this.search.phone != "")
				datas += "&phone="+this.search.phone

			if(this.search.email != "")
				datas += "&email="+this.search.email

			if(this.search.birthdate != "")
				datas += "&birthdate="+this.search.birthdate

			if(this.search.birthplace != "")
				datas += "&birthplace="+this.search.birthplace

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des individus...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registrations?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.persons = r.data.data.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		lock(id, action){
			let vm = this
			let q = (action == 'lock') ? 'bloquer' : 'débloquer'
			this.$swal({
				title: "Blocage / Déblocage",
				text: 'Voulez-vous vraiment '+q+' cet utilisateur ?',
				icon: 'info',
				showCancelButton: true,
				confirmButtonText: 'Oui',
				cancelButtonText: 'Annuler',
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.interceptor = axios.interceptors.request.use((config) => {
						this.$swal('Veuillez patienter', 'Action en cours...', 'info')
						return config
					}, (error) => {
						return Promise.reject(error)
					})

					axios({
						method: 'POST',
						headers: { 'Authorization': 'Bearer ' + this.token },
						url: 'user/toggleEnabled/'+id,
						responseType: 'json'
					})
					.then(r => {    
						if (r.data.state == 'success') {
							this.$swal("C'est fait.", r.data.message, 'success')
							this.getUsers()

						}else{
							this.$swal('Oups !', r.data.message, 'error')
						}
					})
					.catch(function () {
						localStorage.removeItem('user')
						localStorage.removeItem('_rt')
					})
					axios.interceptors.request.eject(this.interceptor)
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
		}
    
	},
	created () {
		this.getPersons()
	}
}
</script>
