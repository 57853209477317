<template>
	<section class="main-container">
		<h1>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Immatriculations
			</router-link>
			&nbsp;
			<i class="fa fa-print"></i>
			Imprimer des cartes
		</h1>

		<div class="row">
			<div class="col-9">
				<form>
					<div class="row">
						<div class="form-group col-3">
							<label for="number">N° d'inscription</label>
							<input type="text" name="number" id="number" class="form-control" v-model="search.number">
						</div>
						<div class="form-group col-6">
							<label for="name">ou Nom & Prénoms</label>
							<input type="text" name="name" id="name" class="form-control" v-model="search.name">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-3">
							<label for="from">Du</label>
							<input type="date" name="from" id="from" class="form-control" v-model="search.from">
						</div>
						<div class="form-group col-3">
							<label for="to">Au</label>
							<input type="date" name="to" id="to" class="form-control" v-model="search.to">
						</div>
						<div class="form-group col-3">
							<label>&nbsp;</label>
							<button class="form-control btn btn-primary" @click.prevent="getPersons()">
								Rechercher
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-5">
				<div>
					<h5 v-if="persons.length > 0">{{persons.length}} résultats</h5>
					<ul class="list-group selectorList" v-if="persons.length > 0">
						<li class="list-group-item toSelect" v-for="p in persons" :key="p.id" :data-id="p.id" @click="selectPerson(p.id)" :class="(selectedPersons.indexOf(p.id) != -1) ? 'on':''">
							<div class="row">
								<div class="col-2">
									<img :src="p.photo" class="id_card_photo thumbnail">
								</div>
								<div class="col-10">
									<span class="bold">{{p.firstname}} {{p.lastname}}</span> <br>
									<span>Né(e) le {{p.birthday | moment("DD/MM/YYYY")}} à {{p.birthplace}}</span> <br>
									<span>N° {{p.registration_number}}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-7" v-if="selectedPersons.length > 0">
				<h3>{{selectedPersons.length}} Cartes sélectionnées</h3>
				<br>
				<button class="btn btn-primary" @click.prevent="printCards()">Imprimer les cartes</button>
			</div>
		</div>

	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'Documents',
	components: { },
	data () {
		return {
			selectedPersons: [],
			persons: [],
			search: {
				number: "",
				name: "",
				from: "",
				to: this.$root.YmdDate()
			},
			documentType: "Fichier unique",
			documents: null
		}
	},
	methods: {
		selectPerson(_id){
			if(this.selectedPersons.indexOf(_id) == -1)
				this.selectedPersons.push(_id)
			else
				this.selectedPersons.splice((this.selectedPersons.indexOf(_id)), 1)

		},
		getPersons () {
			let vm = this

			let datas = ""

			if(this.search.number != "")
				datas += "number="+this.search.number

			if(this.search.name != "")
				datas += "&name="+this.search.name

			if(this.search.from != "")
				datas += "&from="+this.search.from

			if(this.search.to != "")
				datas += "&to="+this.search.to

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des individus...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registrations?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.persons = r.data.data.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		printCards () {
			//let vm = this

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du PDF...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'print/registration?ids='+this.selectedPersons,
				responseType: 'application/json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					window.open(r.data.link.doc)

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}

			})
			.catch(function () {
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
	}
}
</script>
