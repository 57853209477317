<template>
	<section class="main-container">
		<h1>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Immatriculations
			</router-link>
			&nbsp;
			Fiche d'inscription consulaire
			<!--i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{person.firstname}} {{person.lastname}}</span-->
		</h1>

		<!--vue-modaltor :visible="open" @hide="open=false">
			<template #header>
				<!-    add your custom header     ->
				<div>
					<i class="closeicon">x</i>
					<p>add modal title or not</p>
				</div>
			</template>
			<template #body>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
					tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
					quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
					consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
					cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
					proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
				</p>
			</template>
		</vue-modaltor>
		<button @click="open=true">modal-basic</button-->

		<br><br>
		<div :class="(!overlay) ? 'overlay closed' : 'overlay'"></div>
		<aside :class="(!aside) ? 'closed' : ''">
			<div>
				<div class="row" v-if="sendMail">
					<h3>Envoyer un message</h3>
					<br>
					<form method="post" enctype="multipart/form-data">
						<div class="mcontainer">
							<div class="row">
								<div class="form-group col-6">
									<label for="recever">Destinataire</label>
									<input type="text" class="form-control" id="recever" v-model="message.recever">
								</div>
								<div class="form-group col-6">
									<label for="email">Email *</label>
									<input type="email" class="form-control" id="email" v-model="message.email">
								</div>
							</div>
							<div class="row">
								<div class="form-group col-12">
									<label for="object">Objet</label>
									<input type="text" class="form-control" id="object" v-model="message.object">
								</div>
							</div>
							<div class="row">
								<div class="form-group col-12">
									<label for="message">Message *</label>
									<textarea type="text" class="form-control" id="message" v-model="message.message"></textarea>
									<!--vue-editor v-model="message.message"></vue-editor-->
								</div>
							</div>
							<div class="row">
								<div class="form-group col-12">
									<label for="attachment">Pièce jointe</label>
									<input type="file" class="form-control" id="attachment">
								</div>
							</div>
						</div>
						<!--br-->
						<div class="form-group fixedBottom">
							<a href="" class="btn btn-info" @click.prevent="sendMessage()">
								<i class="fa fa-send"></i> &nbsp;&nbsp;&nbsp;
								Envoyer
							</a>
							&nbsp;&nbsp;&nbsp;
							<a href="" class="btn btn-secondary upper" @click.prevent="toggleAside()">
								<i class="fa fa-delete"></i>&nbsp;
								Annuler
							</a>
						</div>
					</form>
				</div>
			</div>
		</aside>

		<div class="row">
			<div class="col-3">
				<div class="center">
					<img :src="person.photo" class="id_card_photo">
				</div>
				<br>
				<div class="card">
					<div class="card-header">
						DOCUMENTS
					</div>
					<div class="card-body">
						<ul v-if="documents && documents.length > 0">
							<li v-for="(d, k) in documents" :key="k">
								<strong>{{d.type}}</strong> [<a :href="d.link" class="" target="_blank">Télécharger</a>]
							</li>
						</ul>
						<div class="alert alert-danger" role="alert" v-else>
							Aucun document enregistré.
						</div>
						<a :href="'./documents/'+person.id" class="btn btn-outline-info btn-block">Ajouter</a>
					</div>
				</div>
				<br>
				<div class="card">
					<div class="card-header">
						SIGNATURE
					</div>
					<div class="card-body">
						<img :src="person.signature" class="id_card_photo">
					</div>
				</div>
			</div>
			<div class="col-9">
				<div>
					<a href="#" class="btn btn-info upper" @click.prevent="generateCard('image')">Générer la carte</a>
					&nbsp;&nbsp;
					<a href="#" class="btn btn-info upper" @click.prevent="generateCard('pdf')">Carte PDF</a>
					&nbsp;&nbsp;
					<a :href="'./edit/'+person.id" class="btn btn-warning upper">
						<i class="fa fa-edit"></i>&nbsp;
						Modifier
					</a>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<a :href="'./edit/sendmail/'+person.id" class="btn btn-primary upper" @click.prevent="toggleAside('message')">
						<i class="fa fa-envelope"></i>&nbsp;
						Envoyer un message
					</a>
					&nbsp;&nbsp;
					<!--a href="#" class="btn btn-outline-info upper">Fiche électorale</a-->
				</div>
				<br>
				<h2>
					N° <span class="text-primary">{{person.registration_number}}</span>
					&nbsp;
					<i class="fa fa-angle-double-right"></i>
					&nbsp;
					<span class="green">{{person.firstname}} {{person.lastname}}</span>
				</h2>
				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Informations personnelles</h3>
					</div>
					<div class="card-body text-dark">
						<div>
							<h5 class="card-title text-primary">Civilité</h5>
							<div>
								<div class="row">
									<div class="col-4">
										<strong>Nom</strong>
										<br>
										<span>{{person.lastname}}</span>
									</div>
									<div class="col-4">
										<strong>Prénoms</strong>
										<br>
										<span>{{person.firstname}}</span>
									</div>
									<div class="col-4">
										<strong>Nom d'usage</strong>
										<br>
										<span>{{person.usename}}</span>
									</div>
								</div>
								<br>
								<div class="row">
									<div class="col-4">
										<strong>Date de naissance</strong>	
										<br>
										<span>{{person.birthday | moment("DD/MM/YYYY")}}</span>
									</div>
									<div class="col-4">
										<strong>Lieu de naissance</strong>	
										<br>
										<span>{{person.birthplace}}</span>
										<br>
										<span>{{person.town_sp_birth}}</span>
									</div>
									<div class="col-4">
										<strong>Pays</strong>	
										<br>
										<span>{{person.country_of_birth}}</span>
									</div>
								</div>
								<br>
								<div class="row">
									<div class="col-4">
										<strong>Couleur des yeux</strong>	
										<br>
										<span>{{person.eye_colour}}</span>
									</div>
									<div class="col-4">
										<strong>Taille</strong>	
										<br>
										<span>{{person.height}} cm</span>
									</div>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Nationalité</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Passeport N°</strong>
									<br>
									<span>{{person.nationality.passeport.number}}</span>
								</div>
								<div class="col-3">
									<strong>Date de délivrance</strong>
									<br>
									<span>{{person.nationality.passeport.date_issue | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Date d'expiration</strong>
									<br>
									<span>{{person.nationality.passeport.expiration_date | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu de délivrance</strong>
									<br>
									<span>{{person.nationality.passeport.place_delivery}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-3">
									<strong>Carte d'identité N°</strong>
									<br>
									<span>{{person.nationality.identity_card.number}}</span>
								</div>
								<div class="col-3">
									<strong>Date de délivrance</strong>
									<br>
									<span>{{person.nationality.identity_card.date_issue | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Date d'expiration</strong>
									<br>
									<span>{{person.nationality.identity_card.expiration_date | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu de délivrance</strong>
									<br>
									<span>{{person.nationality.identity_card.place_delivery}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-3">
									<strong>Certificat Nationalité Ivoirienne N°</strong>
									<br>
									<span>{{person.nationality.ivorian_nationality_certificate.number}}</span>
								</div>
								<div class="col-3">
									<strong>Date de délivrance</strong>
									<br>
									<span>{{person.nationality.ivorian_nationality_certificate.date_issue | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu de délivrance</strong>
									<br>
									<span>{{person.nationality.ivorian_nationality_certificate.place_delivery}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Situation de famille</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Situation matrimoniale</strong>
									<br>
									<span>{{person.family_status.family_link.marital_status}}</span>
								</div>
								<div class="col-3">
									<strong>Date</strong>
									<br>
									<span>{{person.family_status.family_link.date | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu</strong>
									<br>
									<span>{{person.family_status.family_link.place}}</span>
								</div>
							</div>
							<br>
							<strong>Membres de la famille qui résident avec l'individu</strong>
							<br>
							<div class="row" v-for="(m, k) in person.family_status.resident_family_members" :key="k">
								<div class="col-3">
									<strong>Nom</strong>
									<br>
									<span>{{m.nom}}</span>
								</div>
								<div class="col-3">
									<strong>Prénoms</strong>
									<br>
									<span>{{m.prenoms}}</span>
								</div>
								<div class="col-3">
									<strong>Date de naissance</strong>
									<br>
									<span>{{m.date_naissance | moment("DD/MM/YYYY")}}</span>
								</div>
								<div class="col-3">
									<strong>Lieu de naissance</strong>
									<br>
									<span>{{m.lieu_naissance}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Contacts aux États-Unis</h5>

							<div class="row">
								<div class="col-3">
									<strong>Adresse</strong>
									<br>
									<span>{{person.contact_addresses.address.address}}</span>
								</div>
								<div class="col-3">
									<strong>Ville/État</strong>
									<br>
									<span>{{person.contact_addresses.address.city}}</span>
									&nbsp;
									<span>{{person.contact_addresses.address.state}}</span>
								</div>
								<div class="col-3">
									<strong>Code postal</strong>
									<br>
									<span>{{person.contact_addresses.address.postcode}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-3">
									<strong>Téléphone portable</strong>
									<br>
									<span>{{person.contact_addresses.mobile}}</span>
								</div>
								<div class="col-3">
									<strong>Téléphone fixe</strong>
									<br>
									<span>{{person.contact_addresses.phone}}</span>
								</div>
								<div class="col-3">
									<strong>Email</strong>
									<br>
									<span>{{person.contact_addresses.address.postcode}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Situation socio-professionnelle aux États-Unis</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Profession</strong>
									<br>
									<span>{{person.socio_professional_situation.profession}}</span>
								</div>
								<div class="col-3">
									<strong>Employeur</strong>
									<br>
									<span>{{person.socio_professional_situation.employer}}</span>
								</div>
								<div class="col-3">
									<strong>Téléphone professionnel</strong>
									<br>
									<span>{{person.socio_professional_situation.professional_phone}}</span>
								</div>
								<div class="col-3">
									<strong>Email</strong>
									<br>
									<span>{{person.socio_professional_situation.email}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-3">
									<strong>Adresse</strong>
									<br>
									<span>{{person.socio_professional_situation.address.address}}</span>
								</div>
								<div class="col-3">
									<strong>Ville/État</strong>
									<br>
									<span>{{person.socio_professional_situation.address.city}}</span>
									&nbsp;
									<span>{{person.socio_professional_situation.address.state}}</span>
								</div>
								<div class="col-3">
									<strong>Code postal</strong>
									<br>
									<span>{{person.socio_professional_situation.address.postcode}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Situation électorale (Élections présidentielles, référendums, diaspora)</h5>
							<p class="card-text">Demande d'inscription sur la liste électorale consulaire de New-York: {{yesOrNo[person.electoral_situation.registration_electoral_roll]}}</p>
							<br>
							<div class="row">
								<span>
									Inscrit(e) en Côte d'Ivoire sur la liste électorale de
								</span>
								<div class="col-6">
									<strong>Commune</strong>
									<br>
									<span v-if="person.electoral_situation.registration_in_ci">{{person.electoral_situation.registration_in_ci.commune}}</span>
								</div>
								<div class="col-6">
									<strong>Département</strong>
									<br>
									<span v-if="person.electoral_situation.registration_in_ci">{{person.electoral_situation.registration_in_ci.department}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Parents</h3>
					</div>
					<div class="card-body text-dark">
						<div>
							<h5 class="card-title text-primary">Père</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Nom</strong>
									<br>
									<span>{{person.parents.father.lastname}}</span>
								</div>
								<div class="col-3">
									<strong>Prénoms</strong>
									<br>
									<span>{{person.parents.father.firstname}}</span>
								</div>
								<div class="col-3">
									<strong>date de naissance</strong>
									<br>
									<span>{{person.parents.father.birthday | moment("DD/MM/YYYY")}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-3">
									<strong>Ville</strong>
									<br>
									<span>{{person.parents.father.city}}</span>
								</div>
								<div class="col-3">
									<strong>Département</strong>
									<br>
									<span>{{person.parents.father.department}}</span>
								</div>
								<div class="col-3">
									<strong>Pays</strong>
									<br>
									<span>{{person.parents.father.country}}</span>
								</div>
								<div class="col-3">
									<strong>Nationalité</strong>
									<br>
									<span>{{person.parents.father.nationality}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">Mère</h5>
							
							<div class="row">
								<div class="col-3">
									<strong>Nom</strong>
									<br>
									<span>{{person.parents.mother.lastname}}</span>
								</div>
								<div class="col-3">
									<strong>Prénoms</strong>
									<br>
									<span>{{person.parents.mother.firstname}}</span>
								</div>
								<div class="col-3">
									<strong>date de naissance</strong>
									<br>
									<span>{{person.parents.mother.birthday | moment("DD/MM/YYYY")}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-3">
									<strong>Ville</strong>
									<br>
									<span>{{person.parents.mother.city}}</span>
								</div>
								<div class="col-3">
									<strong>Département</strong>
									<br>
									<span>{{person.parents.mother.department}}</span>
								</div>
								<div class="col-3">
									<strong>Pays</strong>
									<br>
									<span>{{person.parents.mother.country}}</span>
								</div>
								<div class="col-3">
									<strong>Nationalité</strong>
									<br>
									<span>{{person.parents.mother.nationality}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Personnes à contacter</h3>
					</div>
					<div class="card-body text-dark">
						<div>
							<h5 class="card-title text-primary">Aux États-Unis</h5>
							
							<div class="row">
								<div class="col-4">
									<strong>Nom</strong>
									<br>
									<span>{{person.people_to_prevent.usa.lastname}}</span>
								</div>
								<div class="col-4">
									<strong>Prénoms</strong>
									<br>
									<span>{{person.people_to_prevent.usa.firstname}}</span>
								</div>
								<div class="col-4">
									<strong>Lien de parenté</strong>
									<br>
									<span>{{person.people_to_prevent.usa['family relationship']}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-4">
									<strong>Adresse</strong>
									<br>
									<span>{{person.people_to_prevent.usa.address.address}}</span>
								</div>
								<div class="col-4">
									<strong>Ville/État</strong>
									<br>
									<span>{{person.people_to_prevent.usa.address.city}}</span>
									&nbsp;
									<span>{{person.people_to_prevent.usa.address.state}}</span>
								</div>
								<div class="col-4">
									<strong>Code postal</strong>
									<br>
									<span>{{person.people_to_prevent.usa.address.postcode}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-4">
									<strong>Téléphone</strong>
									<br>
									<span>{{person.people_to_prevent.usa.phone}}</span>
								</div>
								<div class="col-4">
									<strong>Email</strong>
									<br>
									<span>{{person.people_to_prevent.usa.email}}</span>
								</div>
							</div>
						</div>
						<br>
						<div>
							<h5 class="card-title text-primary">En Côte d'Ivoire</h5>
							<div class="row">
								<div class="col-4">
									<strong>Nom</strong>
									<br>
									<span>{{person.people_to_prevent.ci.lastname}}</span>
								</div>
								<div class="col-4">
									<strong>Prénoms</strong>
									<br>
									<span>{{person.people_to_prevent.ci.firstname}}</span>
								</div>
								<div class="col-4">
									<strong>Lien de parenté</strong>
									<br>
									<span>{{person.people_to_prevent.ci['family relationship']}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-4">
									<strong>Adresse</strong>
									<br>
									<span>{{person.people_to_prevent.ci.address.address}}</span>
								</div>
								<div class="col-4">
									<strong>Ville/État</strong>
									<br>
									<span>{{person.people_to_prevent.ci.address.city}}</span>
								</div>
								<div class="col-4">
									<strong>Code postal</strong>
									<br>
									<span>{{person.people_to_prevent.ci.address.postcode}}</span>
								</div>
							</div>
							<br>
							<div class="row">
								<div class="col-4">
									<strong>Téléphone</strong>
									<br>
									<span>{{person.people_to_prevent.ci.phone}}</span>
								</div>
								<div class="col-4">
									<strong>Email</strong>
									<br>
									<span>{{person.people_to_prevent.ci.email}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
import axios from 'axios'
// import { VueEditor } from "vue2-editor"
export default {
	name: 'Documents',
	components: { },
	props: {
		visible: {
			type: Boolean,
			required: false,
			default: false
		},
		resizeWidth:{
			type:Object,
		},
		animationPanel:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		animationParent:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		bgOverlay:{
			type: String,
			required: false,
			default: '#fff'
		},
		bgPanel:{
			type: String,
			required: false,
			default: '#fff'
		},
		defaultWidth:{
			type: String,
			required: false,
			default: '50%'
		},
		closeScroll:{
			type: Boolean,
			required: false,
			default: true
		}
    },
	data () {
		return {
			open: false,
			uid: null,
			person: null,
			documents: null,
			yesOrNo: {'false':'NON', 'true':'OUI'},
			sendMail: false,
			aside: false,
			overlay: false,
			message: {
				'recever': '',
				'email': '',
				'object': '',
				'message': '',
				'attachments': null
			}
		}
	},
	methods: {
		toggleAside(obj){
			if(!this.aside){
				this.aside = true
				this.overlay = true
			}
			else {
				this.aside = false
				this.overlay = false
			}

			// envoi de message
			if(obj == 'message')
				this.sendMail = true

			else{
				this.sendMail = false
			}
			
		},
		sendMessage(){
			let recever = [{
				'Email' : this.message.email,
				'Name' : this.message.recever,
			}]

			let attachments = []
			//console.log(document.querySelector('#attachment').files[0])
			if (document.querySelector('#attachment').files[0])
				attachments.push(document.querySelector('#attachment').files[0])

			if(attachments.length > 0)
				return this.$root.sendMail (recever, this.message.object, this.message.message, attachments)
			else
				return this.$root.sendMail (recever, this.message.object, this.message.message)
		},
		generateCard(_t){
			//let vm = this

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Génération de la carte...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/generate/'+this.uid+'?type='+_t,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let card = r.data.link
					//console.log(card)
					this.$swal.close()

					let downloadText = (card.type == 'pdf') ? 
						'<div class="col-12 center"> ' +
							'<a href="'+card.doc+'" target="_blank" class="btn btn-primary" download="'+card.doc+'"> ' +
								'Télécharger le PDF' +
							'</a> ' +
						'</div> '
						: 
						'<div class="col-6"> ' +
							'<a href="'+card.recto+'" target="_blank" download="'+card.recto+'"> ' +
								'<img src="'+card.recto+'" class="id_card_photo"> <br>' +
								'Télécharger ' +
							'</a> ' +
						'</div> ' +
						'<div class="col-6"> ' +
							'<a href="'+card.verso+'" target="_blank" download="'+card.verso+'"> ' +
								'<img src="'+card.verso+'" class="id_card_photo"> <br>' +
								'Télécharger ' +
							'</a> ' +
						'</div> '

					this.$swal({
						title: '<strong>Carte générée</strong>',
						icon: 'success',
						html:
							'Vous pouvez télécharger la carte <br> ' +
							'<div class="row"> ' +
								downloadText +
							'</div>',
						showCloseButton: true,
						showCancelButton: false,
						focusConfirm: false,
						confirmButtonText:
							'Terminer',
						confirmButtonAriaLabel: '',
						closeButtonText:
							'Terminer',
						cancelButtonAriaLabel: 'Terminer'
					})

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				console.log(e)
				//vm.$root.relogin()
			})

			axios.interceptors.request.eject(this.interceptor)
		},
		getPerson () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.person = r.data.person

					this.message.recever = this.person.lastname+' '+this.person.firstname
					this.message.email = this.person.contact_addresses.email

					this.$swal.close()

					this.getDocuments()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		getDocuments () {
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/documents/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.documents = r.data.documents.items


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}
	}
}
</script>
