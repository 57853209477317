<template>
	<section class="main-container">
		<h1>Mon compte</h1>

		<div class="row">
			<div class="col-6">
				<h3>Informations personnelles</h3>
				<form>
					<div class="row">
						<div class="form-group col-6">
							<label for="lastname">Nom *</label>
							<input type="text" class="form-control" id="lastname" v-model="user.lastname">
						</div>
						<div class="form-group col-6">
							<label for="firstname">Prénoms *</label>
							<input type="text" class="form-control" id="firstname" v-model="user.firstname">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-6">
							<label for="email">Email *</label>
							<input type="email" class="form-control" id="email" v-model="user.email">
						</div>
						<div class="form-group col-6">
							<label for="phone">Téléphone</label>
							<input type="phone" class="form-control" id="phone" v-model="user.phone">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-6">
							<label for="country">Pays</label>
							<input type="text" class="form-control" id="country" v-model="user.country">
						</div>
						<div class="form-group col-6">
							<label for="city">Ville</label>
							<input type="text" class="form-control" id="city" v-model="user.city">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-4">
							<label for="administrative_id">Matricule</label>
							<input type="text" class="form-control" id="administrative_id" v-model="user.administrative_id" disabled>
						</div>
						<div class="form-group col-4">
							<label for="service">Service</label>
							<input type="text" class="form-control" id="service" v-model="user.service" disabled>
						</div>
						<div class="form-group col-4">
							<label for="office">Poste</label>
							<input type="text" class="form-control" id="office" v-model="user.office" disabled>
						</div>
					</div>
					<br>
					<div class="form-group">
						<input type="submit" name="" value="Enregistrer" class="btn btn-primary" @click.prevent="saveAccount()">
					</div>
				</form>
				
			</div>
			<div class="col-6">
				<h3>Mes permissions</h3>
				<div class="row">
					<div class="form-group col-12">
						<div class="">
							<ul>
								<li
									class="d-block"
									v-for="r in user.roles.items"
									v-bind:key="r.id"
								> 
									<strong class="upper">{{r.name}}</strong>
									<hr>
									<ul class="disc">
										<li
											class="d-block"
											v-for="p in r.permissions.items"
											v-bind:key="p.id"
										> 
											- {{p.name}}
										</li>
										
									</ul>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
				
			</div>
		</div>
	</section>
</template>

<script>
import axios from "axios"
export default {
	name: 'MyAccount',
	components: { },
	data () {
		return {
			user: {
				lastname: null,
				firstname: null,
				email: null,
				phone: null,
				country: null,
				city: null,
				administrative_id: null,
				service: null, 
				office: null,
				selectedRoles: [],
				roles: [],
				uid: null
			}
		}
	},
	methods: {
		saveAccount () {
			let vm = this
			if (this.user.lastname == null) {
				this.$swal('Nom', "Vous devez renseigner votre nom.", 'warning')
				return !1

			} else if (this.user.firstname == null) {
				this.$swal('Nom', "Vous devez renseigner votre prénom.", 'warning')
				return !1

			} else if (this.user.email == null) {
				this.$swal('Nom', "Vous devez renseigner votre adresse email.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Création du rôle en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'PUT',
					headers: this.$root.authHeader(),
					url: 'admin',
					data: {
						'lastname' : this.user.lastname,
						'firstname' : this.user.firstname,
						'email' : this.user.email,
						'phone' : this.user.phone,
						'country' : this.user.country,
						'city' : this.user.city,
						'administrative_id' : this.user.administrative_id,
						'service' : this.user.service,
						'office' : this.user.office,
						'roles' : this.user.selectedRoles
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.$swal("C'est fait", 'Le compte a bien été mis à jour.', 'success')

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getAccount () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des informations personnelles...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'admin',
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.user = r.data.data
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
    
	},
	created () {
		this.getAccount()
	}
}
</script>
