<template>
	<section class="main-container">
		<h1>RENDEZ-VOUS</h1>

	</section>
</template>

<script>

export default {
	name: 'RDV',
	components: { },
	data () {
		return {
		}
	},
	methods: {
    
	},
	created () {
	}
}
</script>
