<template>
    <div class="action-buttons">
        <button class="btn btn-outline-success" @click="handleAction('view')">
            <i class="fa fa-eye"></i>
        </button>
        <button class="btn btn-outline-primary" @click="handleAction('edit')">
            <i class="fa fa-edit"></i>
        </button>
        <button class="btn btn-outline-dark" @click="handleAction('delete')">
            <i class="fa fa-trash"></i>
        </button>
    </div>
</template>
<script>
export default {
    name: "ActionButtons",
    methods: {
        handleAction(actionName) {
            
            switch(actionName){
                case('view'):
                    document.location.href = this.$root.redirectUri +'/passage/'+ this.data.id
                break;
                case('edit'):
                    document.location.href = this.$root.redirectUri +'/passage/edit/'+ this.data.id
                break;
                case('delete'):
                    if(confirm("Confirmez-vous la suppression de ce passage ?"))
                break;
            }
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>