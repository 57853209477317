<template>
	<section class="main-container">
		<h1>Personnes de passage</h1>

		<form>
			<div class="row">
				<div class="form-group col-3">
					<label for="name">Nom ou Prénom</label>
					<input type="text" name="name" id="name" class="form-control" v-model="search.name">
				</div>
				<div class="form-group col-3">
					<label for="profession">Profession</label>
					<input type="text" name="profession" id="profession" class="form-control" v-model="search.profession">
				</div>
				<div class="form-group col-3">
					<label for="from">Passée entre le</label>
					<input type="date" name="from" id="from" class="form-control" v-model="search.from">
				</div>
				<div class="form-group col-3">
					<label for="to">et le</label>
					<input type="date" name="to" id="to" class="form-control" v-model="search.to">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="getAssociations()">
						Rechercher
					</button>
				</div>
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-outline-primary" @click.prevent="resetSearch()">
						Réinitialiser
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>

		<data-table v-bind="parameters" />

		<download-excel
			class="btn btn-warning"
			:data="persons"
			:fields="json_fields"
			worksheet="Personnes de passage"
			name="personnes-de-passage.xls"
		>
			Télécharger .XLS
		</download-excel>
	</section>
</template>

<script>

import axios from 'axios'
import downloadExcel from "vue-json-excel"
import ActionsButtons from '@/components/TransientActionsButtons'
import ArrivalDateInDatatable from '@/components/ArrivalDateInDatatable'
import DepartureDateInDatatable from '@/components/DepartureDateInDatatable'

//this.component("downloadExcel", JsonExcel);

export default {
	name: 'TransientsList',
	components: { downloadExcel },
	data () {
		return {
			persons: [],
			search: {
				name: "",
				profession: "",
				from: "2021-01-01",
				to: this.$root.YmdDate()
			},
			json_fields: {
				"Nom": "lastname",
				"Prénom": "firstname",
				"Profession": "profession",
				"Date d'arrivée": "arrival_date",
				"Date de départ": "departure_date",
				/*"Contacts USA": {
					"Téléphone": "contacts.contact_usa.phone",
					"Adresse": "contacts.contact_usa.address",
					"Ville": "contacts.contact_usa.city",
					"État": "contacts.contact_usa.state",
					"Code postal": "contacts.contact_usa.postcode",
				},*/
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre de personnes par page :entries",
					infoText: "Vue de :first à :last sur :total personnes",
					infoTextFiltered: "Vue de :first à :last sur :filtered (résultats de :total personnes)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche:",
					downloadText: "Exporter en:",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 10,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				data: this.persons,
				showDownloadButton: false,
				columns: [
					{
						key: "lastname",
						title: "Nom"
					},
					{
						key: "firstname",
						title: "Prénom"
					},
					{
						key: "arrival_date.date",
						title: "Arrivée le",
						component: ArrivalDateInDatatable
					},
					{
						key: "departure_date.date",
						title: "Date de départ",
						component: DepartureDateInDatatable
					},
					{
						key: "actions",
						sortable: false,
						component: ActionsButtons
					}
				]
			}
		}
    },
	methods: {
		handleAction(action, payload) {
			console.log(action, payload)
			window.alert("check out the console to see the data logged");
		},
		resetSearch(){
			this.search= {
				lastname: "",
				firstname: "",
				from: "2021-01-01",
				to: this.$root.YmdDate()
			}
			this.getDeposits()
		},
		getPersons () {
			let vm = this

			let datas = ""

			if(this.search.lastname != "")
				datas += "lastname="+this.search.lastname

			if(this.search.firstname != "")
				datas += "&firstname="+this.search.firstname

			if(this.search.from != "")
				datas += "&from="+this.search.from

			if(this.search.to != "")
				datas += "&to="+this.search.to

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des personnes...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'transients?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.persons = r.data.transients.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				console.log(e)
				console.log(vm)
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
    
	},
	created () {
		this.getPersons()
	}
}
</script>
