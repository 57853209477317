<template>
	<section class="main-container">
		<h1>Toutes les demandes d'actes</h1>

		<form>
			<div class="row">
				<div class="form-group col-3">
					<label for="service">Service</label>
					<select type="text" name="service" id="service" class="form-control" v-model="search.service" @change="selectService(s)">
						<option value="">-- Choisissez --</option>
						<option :value="s.service" v-for="s in services" :key="s.service">
							{{s.service}}
						</option>
					</select>
				</div>
				<div class="form-group col-3">
					<label for="act">Acte</label>
					<select type="text" name="act" id="act" class="form-control" v-model="search.act">
						<option value="">-- Choisissez --</option>
						<optgroup :label="s.service" v-for="s in services" :key="s.service" :class="(service != s.service) ? 'hide' : ''">
							
							<option :value="a" v-for="a in s.actes" :key="a">
								{{a}}
							</option>
						</optgroup>
					</select>
				</div>
				<div class="form-group col-3">
					<label for="from">Du</label>
					<input type="date" name="from" id="from" class="form-control" v-model="search.from">
				</div>
				<div class="form-group col-3">
					<label for="to">Au</label>
					<input type="date" name="to" id="to" class="form-control" v-model="search.to">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="getAssociations()">
						Rechercher
					</button>
				</div>
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-outline-primary" @click.prevent="resetSearch()">
						Réinitialiser
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>

		<data-table v-bind="parameters" />

		<download-excel
			class="btn btn-warning"
			:data="acts"
			:fields="json_fields"
			worksheet="Demandes d'actes"
			name="actes-demandes.xls"
		>
			Télécharger .XLS
		</download-excel>
	</section>
</template>

<script>

import axios from 'axios'
import downloadExcel from "vue-json-excel"
import Services from "../../components/Acts/services.js"
import ActionsButtons from '@/components/Acts/ActionsButtons'
import Demandeur from '@/components/Acts/Demandeur'
import CreatedDateInDatatable from '@/components/CreatedDateInDatatable'
export default {
	name: 'ActssList',
	components: { downloadExcel },
	data () {
		return {
			acts: [],
			services: Services,
			selectedService: null,
			search: {
				service: "",
				act: "",
				from: "2021-01-01",
				to: "2022-12-31"
			},
			json_fields: {
				"Service": "service",
				"Acte": "name",
				"Droit de chancellerie": "cost",
				"Code": "code",
				"Demandeur": "person.firstname",
				"Date": "created_at.date",
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre de demandes par page :entries",
					infoText: "Vue de :first à :last sur :total demandes",
					infoTextFiltered: "Vue de :first à :last sur :filtered (résultats de :total demandes)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche:",
					downloadText: "Exporter en:",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 10,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				data: this.acts,
				showDownloadButton: false,
				columns: [
					{
						key: "service",
						title: "Service"
					},
					{
						key: "name",
						title: "Acte"
					},
					{
						key: "cost",
						title: "Coût ($)"
					},
					{
						key: "code",
						title: "Code"
					},
					{
						key: "person.lastname",
						title: "Demandeur",
						component: Demandeur
					},
					{
						key: "created_at.date",
						title: "Enregistrée le",
						component: CreatedDateInDatatable
					},
					{
						key: "actions",
						sortable: false,
						component: ActionsButtons
					}
				]
			}
		}
    },
	methods: {
		handleAction(action, payload) {
			console.log(action, payload)
			window.alert("check out the console to see the data logged");
		},
		resetSearch(){
			this.search= {
				service: "",
				act: "",
				from: "2021-01-01",
				to: this.$root.YmdDate()
			}
			this.getActs()
		},
		getActs () {
			let vm = this

			let datas = ""

			if(this.search.service != "")
				datas += "service="+this.search.service

			if(this.search.act != "")
				datas += "&name="+this.search.act

			if(this.search.from != "")
				datas += "&from="+this.search.from

			if(this.search.to != "")
				datas += "&to="+this.search.to

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des demandes...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'deeds?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.acts = r.data.deeds.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				console.log(e)
				console.log(vm)
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
    
	},
	created () {
		this.getActs()
	}
}
</script>
