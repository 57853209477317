<template>
    <div class="">
        <span>{{data.estimated_withdrawal_date.date | moment("DD/MM/YYYY")}}</span>
    </div>
</template>
<script>
export default {
    name: "WithdrawalDateInDatatable",
    methods: {
        
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    }
};
</script>