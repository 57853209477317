<template>
    <div class="action-buttons">
        <a class="bold" :href="$root.redirectUri+'/immatriculation/'+data.person.id">{{data.person.firstname}} {{data.person.lastname}}</a> <br>
        <span>Né(e) le {{data.person.birthday | moment("DD/MM/YYYY")}} à {{data.person.birthplace}}</span> <br>
        <span>N° {{data.person.registration_number}}</span>
    </div>
</template>
<script>
export default {
    name: "Demandeur",
    methods: {
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>