<template>
	<section class="main-container">
		<h1 v-if="uid">
			<router-link to="/depots" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Modifier un dépôt
			<br>
			<small>Par {{depot.nom}} {{depot.prenom}}, le {{depot.enregistre_le.date | moment("DD/MM/YYYY")}}</small>
		</h1>
		<h1 v-else>Nouveau dépôt</h1>
		<a href="" class="reset_form" @click.prevent="resetForm()">Réinitialiser le formulaire</a>

		<div class="alert alert-info">
			Les champs marqués par une étoile (*) sont obligatoires.
		</div>

		<form>
			<fieldset>
				<legend>Informations sur le dépôt</legend>
				<div class="row">
					<div class="form-group col-6">
						<label for="nom">Nom *</label>
						<input type="text" class="form-control upper" id="nom" v-model="depot.nom" @blur="updateLocalDeposit()">
					</div>
					<div class="form-group col-6">
						<label for="prenom">Prénoms *</label>
						<input type="text" class="form-control upper" id="prenom" v-model="depot.prenom" @blur="updateLocalDeposit()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<label for="type_piece">Type de pièce *</label>
						<input type="text" class="form-control upper" id="type_piece" v-model="depot.type_piece" @blur="updateLocalDeposit()">
					</div>
					<div class="form-group col-6">
						<label for="numero_piece">Numéro de la pièce *</label>
						<input type="text" class="form-control upper" id="numero_piece" v-model="depot.numero_piece" @blur="updateLocalDeposit()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-9">
						<label for="objets">Objets déposés *</label>
						<input type="text" class="form-control" id="objets" v-model="depot.objets" @blur="updateLocalDeposit()">
					</div>
					<div class="form-group col-3">
						<label for="date_de_retrait_estimee">Date de retrait estimée *</label>
						<input type="date" class="form-control" id="date_de_retrait_estimee" v-model="depot.date_de_retrait_estimee" @blur="updateLocalDeposit()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Contact du déposant</legend>
				<div class="row">
					<div class="form-group col-6">
						<label for="tel_usa">Téléphone USA *</label>
						<input type="phone" class="form-control" id="tel_usa" v-model="depot.contact.tel_usa" @blur="updateLocalDeposit()">
					</div>
					<div class="form-group col-6">
						<label for="tel_autre">Autre téléphone</label>
						<input type="phone" class="form-control" id="tel_autre" v-model="depot.contact.tel_autre" @blur="updateLocalDeposit()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<label for="adresse_usa">Adresse USA *</label>
						<input type="text" class="form-control" id="adresse_usa" v-model="depot.contact.adresse_usa" @blur="updateLocalDeposit()">
					</div>
					<div class="form-group col-6">
						<label for="adresse_autre">Autre adresse</label>
						<input type="text" class="form-control" id="adresse_autre" v-model="depot.contact.adresse_autre" @blur="updateLocalDeposit()">
					</div>
				</div>
			</fieldset>

			<div class="form-group">
				<a href="" class="btn btn-primary" @click.prevent="saveData()">
					<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
					Enregistrer
				</a>
			</div>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
export default {
	name: 'NewDeposit',
	components: { },
	data () {
		return {
			uid: null,
			depot: (localStorage.getItem('depot') != 'undefined') ? JSON.parse(localStorage.getItem('depot')) : {
				'nom': null,
				'prenom': null,
				'type_piece': null,
				'numero_piece': null,
				'objets': null,
				'date_de_retrait_estimee': null,
				'enregistre_le': null,
				'contact': {
					'tel_usa': null,
					'tel_autre': null,
					'adresse_usa': null,
					'adresse_autre': null
				}
			}
		}
	},
	methods: {
        resetForm(){
            this.depot = {
				'nom': null,
				'prenom': null,
				'type_piece': null,
				'numero_piece': null,
				'objets': null,
				'date_de_retrait_estimee': null,
				'enregistre_le': null,
				'contact': {
					'tel_usa': null,
					'tel_autre': null,
					'adresse_usa': null,
					'adresse_autre': null
				}
			}
            localStorage.setItem('depot', this.depot)
        },
        updateLocalDeposit() {

            if(this.depot == null){
                this.depot = {
					'nom': null,
					'prenom': null,
					'type_piece': null,
					'numero_piece': null,
					'objets': null,
					'date_de_retrait_estimee': null,
					'enregistre_le': null,
					'contact': {
						'tel_usa': null,
						'tel_autre': null,
						'adresse_usa': null,
						'adresse_autre': null
					}
				}
            }

          localStorage.setItem('depot', JSON.stringify(this.depot))
        },
		saveData () {
			if (this.depot.nom == null) {
				this.$swal('Nom', "Vous devez saisir le nom du déposant.", 'warning')
				return !1

			} else if (this.depot.prenom == null) {
				this.$swal('Prénom', "Vous devez saisir le prénom du déposant.", 'warning')
				return !1

			} else if (this.depot.type_piece == null) {
				this.$swal('Type de pièce', "Vous devez sélectionner le type de pièce du déposant.", 'warning')
				return !1

			} else if (this.depot.numero_piece == null) {
				this.$swal('Numéro de pièce', "Vous devez saisir le numéro de la pièce du déposant.", 'warning')
				return !1

			} else if (this.depot.objets == null) {
				this.$swal('Objets', "Vous devez renseigner les objets déposés.", 'warning')
				return !1

			} else if (this.depot.date_de_retrait_estimee == null) {
				this.$swal('Date de retrait estimée', "Vous devez renseigner la date de retrait estimée.", 'warning')
				return !1

			} else if (this.depot.contact.tel_usa == null) {
				this.$swal('Téléphone aux USA', "Vous devez saisir le contact du déposant aux USA.", 'warning')
				return !1

			} else if (this.depot.contact.adresse_usa == null) {
				this.$swal('Adresse aux USA', "Vous devez saisir l'adresse du déposant aux USA.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement du dépôt en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.uid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.uid == null) ? 'deposited' : 'deposited/'+this.uid

				axios({
					method: requestMethod,
					headers: this.$root.authHeader(),
					url: requestUrl,
					data: {
						'lastname' : this.depot.nom,
						'firstname' : this.depot.prenom,
						'id_type' : this.depot.type_piece,
						'id_number' : this.depot.numero_piece,
						'phone_usa' : this.depot.contact.tel_usa,
						'another_phone' : this.depot.contact.tel_autre,
						'estimated_withdrawal_date' : this.depot.date_de_retrait_estimee,
						'address_usa' : this.depot.contact.adresse_usa,
						'another_address' : this.depot.contact.adresse_autre,
						'objects' : this.depot.objets
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.uid == null) ? 'enregistré' : 'modifié'
						this.$swal("C'est fait", 'Le dépôt a bien été '+w+'.', 'success')

						if(this.uid == null){
							this.depot = {
								'nom': null,
								'prenom': null,
								'type_piece': null,
								'numero_piece': null,
								'objets': null,
								'date_de_retrait_estimee': null,
								'enregistre_le': null,
								'contact': {
									'tel_usa': null,
									'tel_autre': null,
									'adresse_usa': null,
									'adresse_autre': null
								}
							}
						}

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getDeposit () {
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du dépôt...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'deposit/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let u = r.data.deposit

					this.depot.nom = u.lastname
					this.depot.prenom = u.firstname
					this.depot.type_piece = u.id_type
					this.depot.numero_piece = u.id_number
					this.depot.objets = u.objects
					//this.depot.date_de_retrait_estimee = "11/02/2022"
					//this.depot.date_de_retrait_estimee = this.$moment("2022-02-11", "DD/MM/YYYY")
					this.depot.date_de_retrait_estimee = this.$moment(u.estimated_withdrawal_date.date, "DD/MM/YYYY")
					//this.depot.date_de_retrait_estimee = this.$moment("DD/MM/YYYY", u.estimated_withdrawal_date.date)
					this.depot.enregistre_le = u.created_at
					this.depot.contact.tel_usa = u.phone_usa
					this.depot.contact.tel_autre = u.another_phone
					this.depot.contact.adresse_usa = u.address_usa
					this.depot.contact.adresse_autre = u.another_address
					
					//console.log(this.depot)

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getDeposit()
		}

		this.updateLocalDeposit()

	}
}
</script>
