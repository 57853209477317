<template>
	<section class="main-container">
		<h1>Tous les dépôts</h1>

		<form>
			<div class="row">
				<div class="form-group col-6">
					<label for="name">Nom ou Prénom</label>
					<input type="text" name="name" id="name" class="form-control" v-model="search.name">
				</div>
				<div class="form-group col-3">
					<label for="from">Déposé entre le</label>
					<input type="date" name="from" id="from" class="form-control" v-model="search.from">
				</div>
				<div class="form-group col-3">
					<label for="to">et le</label>
					<input type="date" name="to" id="to" class="form-control" v-model="search.to">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-3">
					<label for="objects">Objets déposés</label>
					<input type="text" name="objects" id="objects" class="form-control" v-model="search.objects">
				</div>
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="getAssociations()">
						Rechercher
					</button>
				</div>
				<div class="form-group col-3">
					<label>&nbsp;</label>
					<button class="form-control btn btn-outline-primary" @click.prevent="resetSearch()">
						Réinitialiser
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>

		<data-table v-bind="parameters" />
	</section>
</template>

<script>

import axios from 'axios'
import ActionsButtons from '@/components/DepositActionsButtons'
import CreatedDateInDatatable from '@/components/CreatedDateInDatatable'
import WithdrawalDateInDatatable from '@/components/WithdrawalDateInDatatable'
export default {
	name: 'DepositsList',
	components: { },
	data () {
		return {
			deposits: [],
			search: {
				name: "",
				objects: "",
				from: "2021-01-01",
				to: this.$root.YmdDate()
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre de dépôts par page :entries",
					infoText: "Vue de :first à :last sur :total dépôts",
					infoTextFiltered: "Vue de :first à :last sur :filtered (résultats de :total dépôts)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche:",
					downloadText: "Exporter en:",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 10,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				data: this.deposits,
				columns: [
					{
						key: "lastname",
						title: "Nom"
					},
					{
						key: "firstname",
						title: "Prénom"
					},
					{
						key: "objects",
						title: "Objets"
					},
					{
						key: "estimated_withdrawal_date.date",
						title: "Retrait estimé le",
						component: WithdrawalDateInDatatable
					},
					{
						key: "created_at.date",
						title: "Enregistrée le",
						component: CreatedDateInDatatable
					},
					{
						key: "actions",
						sortable: false,
						component: ActionsButtons
					}
				]
			}
		}
    },
	methods: {
		handleAction(action, payload) {
			console.log(action, payload)
			window.alert("check out the console to see the data logged");
		},
		resetSearch(){
			this.search= {
				lastname: "",
				firstname: "",
				objects: "",
				from: "2021-01-01",
				to: this.$root.YmdDate()
			}
			this.getDeposits()
		},
		getDeposits () {
			let vm = this

			let datas = ""

			if(this.search.lastname != "")
				datas += "lastname="+this.search.lastname

			if(this.search.firstname != "")
				datas += "&firstname="+this.search.firstname

			if(this.search.objects != "")
				datas += "&office="+this.search.objects

			if(this.search.from != "")
				datas += "&from="+this.search.from

			if(this.search.to != "")
				datas += "&to="+this.search.to

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des dépôts...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'deposits?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.deposits = r.data.deposits.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				console.log(e)
				console.log(vm)
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
    
	},
	created () {
		this.getDeposits()
	}
}
</script>
