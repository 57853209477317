<template>
	<section class="main-container">
		<h1>
			<router-link to="/acts" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Demandes d'actes
			</router-link>
			&nbsp;
			<i class="fa fa-pie-chart"></i>
			Statistiques demandes d'actes
			<!--i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{association.corporate_name}}</span-->
		</h1>

		<form>
			<div class="row">
				<div class="form-group col-3">
					<label for="service">Service</label>
					<select type="text" name="service" id="service" class="form-control" v-model="search.service" @change="selectService(s)">
						<option value="">-- Choisissez --</option>
						<option :value="s.service" v-for="s in services" :key="s.service">
							{{s.service}}
						</option>
					</select>
				</div>
				<div class="form-group col-3">
					<label for="act">Acte</label>
					<select type="text" name="act" id="act" class="form-control" v-model="search.name">
						<option value="">-- Choisissez --</option>
						<optgroup :label="s.service" v-for="s in services" :key="s.service" :class="(service != s.service) ? 'hide' : ''">
							
							<option :value="a" v-for="a in s.actes" :key="a">
								{{a}}
							</option>
						</optgroup>
					</select>
				</div>
				<div class="form-group col-3">
					<label for="from">Du</label>
					<input type="date" name="from" id="from" class="form-control" v-model="search.from">
				</div>
				<div class="form-group col-3">
					<label for="to">Au</label>
					<input type="date" name="to" id="to" class="form-control" v-model="search.to">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-2">
					<label for="gender">Genre</label>
					<select name="gender" id="gender" class="form-control" v-model="search.gender">
						<option value="">-- Choisissez --</option>
						<option value="f">Féminin</option>
						<option value="m">Masculin</option>
					</select>
				</div>
				<div class="form-group col-2">
					<label for="min_age">Âge minimum</label>
					<input type="number" name="min_age" id="min_age" placeholder="0" class="form-control" v-model="search.min_age">
				</div>
				<div class="form-group col-2">
					<label for="max_age">Âge maximun</label>
					<input type="number" name="max_age" id="max_age" placeholder="100" class="form-control" v-model="search.max_age">
				</div>
				<div class="form-group col-2">
					<label>ou âge (précis)</label>
					<select class="form-control" v-model="search.age">
						<option value="">Tous</option>
						<option :value="age" v-for="age in 100" :key="age">{{age}}</option>
					</select>
				</div>
				<div class="form-group col-2">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="getStats()">
						Rechercher
					</button>
				</div>
				<div class="form-group col-2">
					<label>&nbsp;</label>
					<button class="form-control btn btn-outline-primary" @click.prevent="resetSearch()">
						Réinitialiser
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>
		<br>
		<div class="row">
			<h3>{{stats.total}} demandes</h3>
			<div class="card-deck" v-if="!service && stats.services">
				<div class="card mb-4" style="max-width: 33%;"
					v-for="(s, k) in stats.services"
					:key="k"
				>
					<div class="card-header bg-primary upper bold text-white">
						{{k}}
					</div>
					<div class="card-body">
						<ul class="list-group">
							<li class="list-group-item d-flex justify-content-between align-items-center" v-for="(a, i) in s" :key="i">
								{{Object.keys(a)[0]}}
								<span class="badge badge-success badge-pill">{{Object.values(a)[0]}}</span>
							</li>
						</ul>

						<!--h5 class="card-title stat-number">3</h5-->
					</div>
				</div>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-12">
				<h3 class="center">Tendance des demandes</h3>
				<ve-line :data="chartData"></ve-line>
			</div>
		</div>
		<br>
		<div>
			<h3>Liste des {{stats.total}} demandes</h3>

			<data-table v-bind="parameters" />

			<download-excel
				class="btn btn-warning"
				:data="deeds"
				:fields="json_fields"
				worksheet="Demandes d'actes"
				name="actes-demandes.xls"
			>
				Télécharger .XLS
			</download-excel>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import VeLine from 'v-charts/lib/line.common'
import Services from "../../components/Acts/services.js"
import downloadExcel from "vue-json-excel"
import Demandeur from '@/components/Acts/Demandeur'
import CreatedDateInDatatable from '@/components/CreatedDateInDatatable'

export default {
	name: 'ActsStats',
	components: { downloadExcel, VeLine },
	props: {
		
    },
	data () {
		return {
			deeds: null,
			stats: null,
			service: null,
			services: Services,
			search: {
				service: "",
				name: "",
				gender: "",
				min_age: 0,
				max_age: 130,
				age: null,
				from: "2021-01-01",
				to: this.$root.YmdDate()
			},
			json_fields: {
				"Service": "service",
				"Acte": "name",
				"Droit de chancellerie": "cost",
				"Code": "code",
				"Demandeur": "person.firstname",
				"Date": "created_at.date",
			},
			chartData: {
				columns: ['Acte', 'Demande'],
				rows: []
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre de demandes par page :entries",
					infoText: "Vue de :first à :last sur :total demandes",
					infoTextFiltered: "Vue de :first à :last sur :filtered (résultats de :total demandes)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche:",
					downloadText: "Exporter en:",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 10,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				data: this.deeds,
				showDownloadButton: false,
				columns: [
					{
						key: "service",
						title: "Service"
					},
					{
						key: "name",
						title: "Acte"
					},
					{
						key: "cost",
						title: "Coût ($)"
					},
					{
						key: "code",
						title: "Code"
					},
					{
						key: "person.lastname",
						title: "Demandeur",
						component: Demandeur
					},
					{
						key: "created_at.date",
						title: "Enregistrée le",
						component: CreatedDateInDatatable
					}
				]
			}
		}
    },
	methods: {
		resetSearch(){
			this.search= {
				service: "",
				name: "",
				gender: "",
				min_age: 0,
				max_age: 130,
				age: null,
				from: "2021-01-01",
				to: this.$root.YmdDate()
			}
			this.getStats()
		},
		getStats() {

			let datas = "?"

			if(this.search.service != "")
				datas += "&service="+this.search.service

			if(this.search.name != "")
				datas += "&name="+this.search.name
			
			if(this.search.gender != "")
				datas += "&gender="+this.search.gender
			
			if(this.search.min_age != 0)
				datas += "&min_age="+this.search.min_age
			
			if(this.search.age != null)
				datas += "&age="+this.search.age

				datas += "&from="+this.search.from
				datas += "&to="+this.search.to

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'deed/stats'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.stats = r.data.stats
					this.deeds = r.data.deeds.items

					this.chartData = {
						columns: ['Acte', 'Demande'],
						rows: []
					}

					for (const v of Object.entries(this.stats.services)) {
						for (const [kv, vv] of Object.entries(v[1])) {
							console.log(kv)
							this.chartData.rows.push({ 'Acte': Object.keys(vv)[0], 'Demande': Object.values(vv)[0] })
						}
					}
					
				}else{
					console.log(r.data.message)
				}
			})
		}
		
	},
	created () {
		this.getStats()
	}
}
</script>
