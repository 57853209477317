<style scoped>
	#sidebar{
		display: none !important;
	}
</style>

<template>
	<section class="main-container">
		<div class="container">   
			<h1>Authentification</h1> 
			<div id="loginbox" style="margin-top:50px;" class="mainbox col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">                    
				<div class="panel panel-info" >
                    <div class="panel-heading">
                        <div class="panel-title">Entrez vos identifiants</div>
                        <!--div style="float:right; font-size: 80%; position: relative; top:-10px"><a href="#">Forgot password?</a></div-->
                    </div>     

                    <div style="padding-top:30px" class="panel-body" >

                        <div style="display:none" id="login-alert" class="alert alert-danger col-sm-12"></div>
                            
                        <form id="loginform" class="form-horizontal" role="form">
                                    
                            <div style="margin-bottom: 25px" class="input-group">
                                        <span class="input-group-addon"><i class="glyphicon glyphicon-user"></i></span>
                                        <input id="login-username" type="text" class="form-control" name="username" value="" placeholder="Adresse email" v-model="email">                                        
                                    </div>
                                
                            <div style="margin-bottom: 25px" class="input-group">
                                    <span class="input-group-addon"><i class="glyphicon glyphicon-lock"></i></span>
                                    <input id="login-password" type="password" class="form-control" name="password" placeholder="Mot de passe" v-model="password">
                                </div>
                            <div style="margin-top:10px" class="form-group">
                                <!-- Button -->

                                <div class="">
                                  <button id="btn-login" href="#" class="btn btn-primary" @click.prevent="login()">Connexion</button>
                                </div>
                            </div>
                        </form>  
                    </div>                     
                </div>  
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
//import yapOauth2 from '@/assets/js/yap-oauth2'
export default {
	name: 'Login',
	components: { },
	data () {
		return {
			email: null,
			password: null,
			user: null
		}
	},
	methods: {
		/*init(){
			axios({
				method: 'POST',
				url: 'account/init',
				data: {
					'lastname' : 'Yaou',
					'firstname' : 'YAPO',
					'email' : 'monsieur.yapo@gmail.com',
					'password' : 'Equinox@21',
					'office' : 'Prestataire'
				},
				responseType: 'json'
			})
			.then(r => {
				console.log(r)
			})
		},*/
		login(){
			if (this.email.length < 5) {
				this.$swal('Adresse email', 'Vous devez saisir votre adresse email.', 'warning')
			} else if (this.password == null) {
				this.$swal('Mot de passe', 'Il manque votre mot de passe.', 'warning')
			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Connexion', 'Authentification en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'POST',
					url: 'access_token',
					data: {
						"username": this.email,
						"password": this.password,
						"grant_type": "password",
						"client_id": "adminapp",
						"client_secret": "Pmkl?@0i"
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.access_token) {
						this.$root.setToken (r.data.access_token, r.data.refresh_token)

						axios({
							method: 'GET',
							headers: this.$root.authHeader(),
							url: 'admin',
							responseType: 'json'
						})
						.then(r => {    
							if (r.data.state == 'success') {
								this.user = r.data.data
								
								let permissions = []

								this.user.roles.items.forEach(function(r){
									r.permissions.items.forEach(function(p){
										if(permissions.indexOf(p.id) == -1)
											permissions.push(p.id)
									})
								})

								localStorage.setItem('_p', JSON.stringify(permissions))

								this.$swal.close()
								document.location.href = this.$root.redirectUri

							}else{
								this.$swal('Oups !', r.data.message, 'error')
							}
						})
					}
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		}
	},
	created () {
		//this.$root.isRefreshTokenValid()
		//this.init()
		if(localStorage.getItem('actk') && this.$root.isTokenValid())
			document.location.href = history.back()

		else if(localStorage.getItem('actk') && !this.$root.isTokenValid())
			this.$root.refreshAccessToken()
		
	}
}
</script>
