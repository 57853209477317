<template>
	<section class="main-container">
		<h1 v-if="uid">
			<router-link to="/accounts" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Modifier une association
			<br>
			<small>{{firstname}}</small>
		</h1>
		<h1 v-else>Nouvelle association</h1>
		<a href="" class="reset_form" @click.prevent="resetForm()">Réinitialiser le formulaire</a>

		<div class="alert alert-info">
			Les champs marqués par une étoile (*) sont obligatoires.
		</div>

		<form enctype="multipart/form-data">
			<div class="aic_popup hide">
				<div class="center">
					<div id="for_photo" v-if="popupFor == 'photo'">
						<h4>Ajouter le logo</h4>
						
						<input class="selectFile" type="file" ref="photo" @change="loadImage($event, 'photo')" accept="image/*">
						<button id="btnPhoto" class="button btn btn-outline-primary" @click.prevent="$refs.photo.click()">
							Sélectionner un logo
						</button>
						<br><br>
						<cropper
							ref="photoCropper"
							class="upload-example-cropper wide"
							:src="photo.src"
							:stencil-props="{
								
							}"
						/>
						<br>
						<div class="button-wrapper">
							<button class="button btn btn-primary" @click.prevent="instanciateFile('photo')">
								Valider
							</button>
						</div>
					</div>
					<button class="btn btn-outline-secondary topup" @click.prevent="closePopup()">Annuler</button>
				</div>
			</div>
			<fieldset>
				<legend>Identité visuelle</legend>
				<div class="row">
					<div class="form-group col-8">
						<div class="row">
							<div class="form-group col-6">
								<label for="photo" @click="openPopup('photo')" class="btn btn-label">
									Ajouter un logo *
								</label>
								<br><br>
								<div class="preview_picture">
									<img :src="photoPreview" alt="" class="photoPreview">
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Informations sur l'association</legend>
				<div class="row">
					<div class="form-group col-4">
						<label for="denomination_sociale">Dénomination sociale *</label>
						<input type="text" class="form-control upper" id="denomination_sociale" v-model="association.denomination_sociale" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-2">
						<label for="effectif">Éffectif *</label>
						<input type="numbre" class="form-control upper" id="effectif" v-model="association.effectif" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-2">
						<label for="type">Type</label>
						<select class="form-control upper" id="type" v-model="association.type" @blur="updateLocalAssociation()">
							<option value="Association">Association</option>
							<option value="ONG">ONG</option>
						</select>
					</div>
					<div class="form-group col-4">
						<label for="siege_social">Siège social</label>
						<input type="text" class="form-control upper" id="siege_social" v-model="association.siege_social" @blur="updateLocalAssociation()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<label for="objet_social">Objet social *</label>
						<textarea type="text" class="form-control upper" id="objet_social" v-model="association.objet_social" @blur="updateLocalAssociation()"></textarea>
					</div>
					<div class="form-group col-6">
						<label for="branche_activites">Branches d'activité</label>
						<textarea type="text" class="form-control upper" id="branche_activites" v-model="association.branche_activites" @blur="updateLocalAssociation()"></textarea>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="date_de_creation">Date de création *</label>
						<input type="date" class="form-control" id="date_de_creation" v-model="association.date_de_creation" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-3">
						<label for="lieu_de_creation">Lieu de création *</label>
						<input type="text" class="form-control capitalize" id="lieu_de_creation" v-model="association.lieu_de_creation" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-3">
						<label for="programme_activite">Programme d'activité</label>
						<select name="programme_activite" id="programme_activite" class="form-control" v-model="association.programme_activite">
							<option value="mensuel">MENSUEL</option>
							<option value="trimestriel">TRIMESTRIEL</option>
							<option value="semestriel">SEMESTRIEL</option>
							<option value="annuel">ANNUEL</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="nationalite">Nationalité *</label>
						<select class="form-control" id="nationalite" v-model="association.nationalite" @change="updateLocalAssociation()">
							<option value="">Choisissez</option>
							<option :value="k" v-for="(p, k) in $root.pays" :key="k">{{p}}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-4">
						<label for="date_naissance">Nature *</label>
						<div class="input-group form-group">
							<label>
								<input type="checkbox" name="nature[]" value="Commerciale" class="selectElt" @click="addElt('Commerciale', 'nature')"> Commerciale
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Culturelle" class="selectElt" @click="addElt('Culturelle', 'nature')"> Culturelle
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Économique" class="selectElt" @click="addElt('Économique', 'nature')"> Économique
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Humanitaire" class="selectElt" @click="addElt('Humanitaire', 'nature')"> Humanitaire
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Industrielle" class="selectElt" @click="addElt('Industrielle', 'nature')"> Industrielle
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="ONG" class="selectElt" @click="addElt('ONG', 'nature')"> ONG
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Professionnelle" class="selectElt" @click="addElt('Professionnelle', 'nature')"> Professionnelle
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Religieuse" class="selectElt" @click="addElt('Religieuse', 'nature')"> Religieuse
							</label>
							<br>
							<label>
								<input type="checkbox" name="nature[]" value="Sociale" class="selectElt" @click="addElt('Sociale', 'nature')"> Sociale
							</label>
						</div>
					</div>
					<div class="form-group col-4">
						<label for="date_naissance">Catégorie *</label>
						<div class="input-group form-group">
							<label>
								<input type="checkbox" name="categorie[]" value="Autonomie" class="selectElt" @click="addElt('Autonomie', 'categorie')"> Autonomie
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Confédération" class="selectElt" @click="addElt('Confédération', 'categorie')"> Confédération
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Confrerie" class="selectElt" @click="addElt('Confrerie', 'categorie')"> Confrerie
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Ethnique" class="selectElt" @click="addElt('Ethnique', 'categorie')"> Ethnique
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Fédération" class="selectElt" @click="addElt('Fédération', 'categorie')"> Fédération
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Mutuelle" class="selectElt" @click="addElt('Mutuelle', 'categorie')"> Mutuelle
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Parti politique" class="selectElt" @click="addElt('Parti politique', 'categorie')"> Parti politique
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Régionale" class="selectElt" @click="addElt('Régionale', 'categorie')"> Régionale
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Tribu" class="selectElt" @click="addElt('Tribu', 'categorie')"> Tribu
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Utilité publique" class="selectElt" @click="addElt('Utilité publique', 'categorie')"> Utilité publique
							</label>
							<br>
							<label>
								<input type="checkbox" name="categorie[]" value="Village" class="selectElt" @click="addElt('Village', 'categorie')"> Village
							</label>
						</div>
					</div>
					<div class="form-group col-2">
						<label for="numero_immatriculation">N° d'immatriculation *</label>
						<input type="text" class="form-control" id="numero_immatriculation" placeholder="" v-model="association.numero_immatriculation" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-2">
						<label for="date_immatriculation">Immatriculée le</label>
						<input type="date" class="form-control" id="date_immatriculation" placeholder="" v-model="association.date_immatriculation" @blur="updateLocalAssociation()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Adresse de l'association</legend>
				<div class="row">
					<div class="form-group col-4">
						<label for="adresse">Adresse</label>
						<input type="text" class="form-control" id="adresse" v-model="association.adresse.adresse" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-4">
						<label for="adresse_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="adresse_ville" placeholder="" v-model="association.adresse.ville" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-4">
						<label for="adresse_etat">État</label>
						<input type="text" class="form-control capitalize" id="adresse_etat" placeholder="" v-model="association.adresse.etat" @blur="updateLocalAssociation()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-4">
						<label for="adresse_code_postal">Code postal</label>
						<input type="text" class="form-control" id="adresse_code_postal" placeholder="" v-model="association.adresse.code_postal" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-4">
						<label for="telephone">Téléphone</label>
						<input type="phone" class="form-control" id="telephone" v-model="association.adresse.telephone" @blur="updateLocalAssociation()">
					</div>
					<div class="form-group col-4">
						<label for="email">Email</label>
						<input type="email" class="form-control" id="email" v-model="association.adresse.email" @blur="updateLocalAssociation()">
					</div>
				</div>
			</fieldset>

			<div class="form-group">
				<a href="" class="btn btn-primary" @click.prevent="saveData()">
					<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
					Enregistrer
				</a>
			</div>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
	name: 'NewAsso',
	components: { Cropper },
	data () {
		return {
			photo: {
				src: null,
				type: null
			},
			uid: null,
			popupFor: 'photo',
			photoPreview: null,
			photo_: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
			association: (localStorage.getItem('association') != 'undefined') ? JSON.parse(localStorage.getItem('association')) : {
				'type': 'Association',
				'denomination_sociale': "",
				'siege_social': "",
				'objet_social': "",
				'branche_activites': "",
				'effectif': "",
				'date_de_creation': "",
				'lieu_de_creation': "",
				'programme_activite': "",
				'nationalite': "",
				'nature': [],
				'categorie': [],
				'numero_immatriculation': "",
				'date_immatriculation': "",
				'logo': "",
				'adresse': {
					'adresse': "",
					'ville': "",
					'etat': "",
					'code_postal': "",
				},
				'telephone': "",
				'email': ""
			}
		}
	},
	methods: {
		closePopup(){
			document.querySelector('.aic_popup').classList.add("hide")
		},
		openPopup(t){
			document.querySelector('.aic_popup').classList.remove("hide")
			this.popupFor = t
			this.reset(t)
		},
		instanciateFile(t) {
			const { canvas } = (t == 'photo') ? this.$refs.photoCropper.getResult() : this.$refs.signatureCropper.getResult()
			
			if (canvas) {
				let vm = this
				canvas.toBlob(blob => {
					const reader = new FileReader()

					switch(t){
						case('photo'): vm.photo_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.photoPreview = reader.result
								
							};
						break;
					}

				}, 'image/jpeg');

				document.querySelector('.aic_popup').classList.add("hide")
			}
		},
		reset(t) {
			if(t == 'photo'){
				this.photo = {
					src: null,
					type: null
				}
			}
		},
		loadImage(event, t) {

			const { files } = event.target

			if (files && files[0]) {

				const blob = URL.createObjectURL(files[0])
				const reader = new FileReader()

				switch(t){
					case('photo'):
						if (this.photo.src) {
							URL.revokeObjectURL(this.photo.src)
						}

						reader.onload = (e) => {

							this.photo = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
				}

				reader.readAsArrayBuffer(files[0]);
			}
		},
		getImgData(files) {

			if (files) {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(files);
				fileReader.addEventListener("load", function () {
					return this.result
				});    
			}
		},
		choosePhoto(){
			this.photo_ = document.querySelector('#photo').files[0]
			console.log(this.getImgData(this.photo_))
			//console.log(this.photo_)
		},
		chooseSignature(){
			this.signature_ = document.querySelector('#signature').files[0]
		},
		addElt(v, n){
			let elt = document.querySelector('input[value="'+v+'"]')

			switch(n){
				case('nature'):
					if(this.association.nature.indexOf(v) == -1 && elt.checked){
						this.association.nature.push(v)

					}
					else{
						this.association.nature.splice(this.association.nature.indexOf(v),1)
					}
				break;
				case('categorie'):
					if(this.association.categorie.indexOf(v) == -1 && elt.checked){
						this.association.categorie.push(v)

					}
					else{
						this.association.categorie.splice(this.association.categorie.indexOf(v),1)
					}
				break;
			}

			this.updateLocalAssociation()
			//console.log(this.association.nature, this.association.categorie)
		},
        resetForm(){
            this.association = {
				'type': 'Association',
				'denomination_sociale': "",
				'siege_social': "",
				'objet_social': "",
				'branche_activites': "",
				'effectif': "",
				'date_de_creation': "",
				'lieu_de_creation': "",
				'programme_activite': "",
				'nationalite': "",
				'nature': [],
				'categorie': [],
				'numero_immatriculation': "",
				'date_immatriculation': "",
				'logo': "",
				'adresse': {
					'adresse': "",
					'ville': "",
					'etat': "",
					'code_postal': "",
				},
				'telephone': "",
				'email': ""
			}
            localStorage.setItem('association', this.association)
        },
        updateLocalAssociation() {

            if(this.association == null){
                this.association = {
					'type': 'Association',
					'denomination_sociale': "",
					'siege_social': "",
					'objet_social': "",
					'branche_activites': "",
					'effectif': "",
					'date_de_creation': "",
					'lieu_de_creation': "",
					'programme_activite': "",
					'nationalite': "",
					'nature': [],
					'categorie': [],
					'numero_immatriculation': "",
					'date_immatriculation': "",
					'logo': "",
					'adresse': {
						'adresse': "",
						'ville': "",
						'etat': "",
						'code_postal': "",
					},
					'telephone': "",
					'email': ""
				}
            }

          localStorage.setItem('association', JSON.stringify(this.association))
        },
		saveData () {
			let vm = this

			//let nat = document.getElementsByName('nature[]')
			//console.log(nat)

			if (this.association.denomination_sociale == null) {
				this.$swal('Nom', "Vous devez saisir le nom de l'association.", 'warning')
				return !1

			} else if (this.association.effectif == null) {
				this.$swal('Nom', "Vous devez préciser l'effectif de l'association.", 'warning')
				return !1

			} else if (this.association.objet_social == null) {
				this.$swal('Nom', "Vous devez saisir l'objet social de l'association.", 'warning')
				return !1

			} else if (this.association.date_de_creation == null) {
				this.$swal('Nom', "Vous devez renseigner la date de création de l'association.", 'warning')
				return !1

			} else if (this.association.lieu_de_creation == null) {
				this.$swal('Nom', "Vous devez renseigner le lieu de création de l'association.", 'warning')
				return !1

			} else if (this.association.nationalite == null) {
				this.$swal('Nom', "Vous devez indiquer la nationalité de l'association.", 'warning')
				return !1

			} else if (this.association.nature == null) {
				this.$swal('Nom', "Vous devez préciser la nature de l'association.", 'warning')
				return !1

			} else if (this.association.categorie == null) {
				this.$swal('Nom', "Vous devez indiquer au moins une catégorie pour l'association.", 'warning')
				return !1

			} else if (this.association.numero_immatriculation == null) {
				this.$swal('Nom', "Vous devez saisir le numéro d'enregistrement de l'association.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', "Enregistrement de l'association en cours...", 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.uid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.uid == null) ? 'association' : 'association/'+this.uid

				let datas = new FormData()

				console.log(this.association)

				datas.append('logo', this.photo_)
				datas.append('type', this.association.type)
				datas.append('corporate_name', this.association.denomination_sociale)
				datas.append('head_office', this.association.siege_social)
				datas.append('social_object', this.association.objet_social)
				datas.append('effective', this.association.effectif)
				datas.append('creation_date', this.association.date_de_creation)
				datas.append('place_creation', this.association.lieu_de_creation)
				datas.append('activity_program', this.association.programme_activite)
				datas.append('nationality', this.association.nationalite)
				datas.append('nature', this.association.nature.join())
				datas.append('category', this.association.categorie.join())
				datas.append('branch_activity', this.association.branche_activites)
				datas.append('registration_number', this.association.numero_immatriculation)
				datas.append('association_date', this.association.date_immatriculation)
				datas.append('city', this.association.adresse.ville)
				datas.append('state', this.association.adresse.etat)
				datas.append('address', this.association.adresse.adresse)
				datas.append('postal_code', this.association.adresse.code_postal)
				datas.append('phone', this.association.telephone)
				datas.append('email', this.association.email)

				axios({
					method: requestMethod,
					headers: this.$root.authHeaderFormData(),
					url: requestUrl,
					data: datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.uid == null) ? 'enregistrée' : 'modifiée'
						this.$swal("C'est fait", "L'association a bien été "+w+".", 'success')

						if(this.uid == null){
							this.association = {
								'type': 'Association',
								'denomination_sociale': "",
								'siege_social': "",
								'objet_social': "",
								'branche_activites': "",
								'effectif': "",
								'date_de_creation': "",
								'lieu_de_creation': "",
								'programme_activite': "",
								'nationalite': "",
								'nature': [],
								'categorie': [],
								'numero_immatriculation': "",
								'date_immatriculation': "",
								'logo': "",
								'adresse': {
									'adresse': "",
									'ville': "",
									'etat': "",
									'code_postal': "",
								},
								'telephone': "",
								'email': ""
							}
							//this.selectedRoles = []
						}

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					//console.log(e)
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getUser () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement du compte utilisateur...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'user/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let u = r.data.user

					this.lastname = u.lastname
					this.firstname = u.firstname
					this.email = u.email
					this.phone = u.phone
					this.country = u.country
					this.city = u.city
					this.administrative_id = u.administrative_id
					this.service = u.service
					this.occupation = u.occupation
					
					u.roles.items.forEach(e => {
						
						if(this.selectedRoles.indexOf(e.id) == -1){
							this.selectedRoles.push(e.id)
							document.querySelector('input.selectElt[value="'+e.id+'"]').checked = true
						}
					})

					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		//this.getRoles()
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getUser()
		}

		this.updateLocalAssociation()

	},
	destroyed() {
		if (this.photo.src) {
			URL.revokeObjectURL(this.photo.src)
		}
	}
}
</script>
