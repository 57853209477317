<template>
	<section class="main-container">
		<h1>
			<router-link to="/depots" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Dépôts
			</router-link>
			&nbsp;
			Consulter un dépôt
			<!--i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{association.corporate_name}}</span-->
		</h1>

		<div class="row">
			<div class="col-12">
				<div class="top_right">
					<a :href="'./edit/'+deposit.id" class="btn btn-warning upper">
						<i class="fa fa-edit"></i>&nbsp;
						Modifier
					</a>
					&nbsp;&nbsp;&nbsp;
					<button class="btn btn-danger upper">
						Supprimer
					</button>
				</div>
				<h2>
					<span class="text-primary">{{deposit.lastname}} {{deposit.firstname}}</span>
					&nbsp;
					<i class="fa fa-angle-double-right"></i>
					&nbsp;
					<span class="green">{{deposit.created_at.date | moment("DD/MM/YYYY")}}</span>
				</h2>
			</div>
		</div>
		<br><br>
		<div class="row">
			<div class="col-12">
				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Informations du dépôt</h3>
					</div>
					<div class="card-body text-dark">
						<div class="row">
							<div class="col-12">
								<div>
									<div>
										<div class="row">
											<div class="col-4">
												<strong>Déposant</strong>
												<br>
												<span>{{deposit.lastname}} {{deposit.firstname}}</span>
											</div>
											<div class="col-4">
												<strong>Pièce d'identité</strong>
												<br>
												<span>{{deposit.id_type}} N° {{deposit.id_number}}</span>
											</div>
											<div class="col-4">
												<strong>Objets</strong>
												<br>
												<span>{{deposit.objects}}</span>
											</div>
										</div>
										<br>
										<div class="row">
											<div class="col-4">
												<strong>Date de dépôt</strong>	
												<br>
												<span>{{deposit.created_at.date | moment("DD/MM/YYYY")}}</span>
											</div>
											<div class="col-4">
												<strong>Date de retrait estimée</strong>	
												<br>
												<span>{{deposit.estimated_withdrawal_date.date | moment("DD/MM/YYYY")}}</span>
											</div>
										</div>
									</div>
								</div>
								<br>
								<div>
									<h5 class="card-title text-primary">Contacts du déposant</h5>

									<div class="row">
										<div class="col-4">
											<strong>Adresse USA</strong>
											<br>
											<span>{{deposit.address_usa}}</span>
										</div>
										<div class="col-4">
											<strong>Téléphone USA</strong>
											<br>
											<span>{{deposit.phone_usa}}</span>
										</div>
									</div>
									<div class="row">
										<div class="col-4">
											<strong>Autre adresse</strong>
											<br>
											<span>{{deposit.another_address}}</span>
										</div>
										<div class="col-4">
											<strong>Autre téléphone</strong>
											<br>
											<span>{{deposit.another_phone}}</span>
										</div>
									</div>
								</div>
								<br>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Deposit',
	components: { },
	props: {
		visible: {
			type: Boolean,
			required: false,
			default: false
		},
		resizeWidth:{
			type:Object,
		},
		animationPanel:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		animationParent:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		bgOverlay:{
			type: String,
			required: false,
			default: '#fff'
		},
		bgPanel:{
			type: String,
			required: false,
			default: '#fff'
		},
		defaultWidth:{
			type: String,
			required: false,
			default: '50%'
		},
		closeScroll:{
			type: Boolean,
			required: false,
			default: true
		}
    },
	data () {
		return {
			open: false,
			uid: null,
			deposit: null,
			yesOrNo: {'false':'NON', 'true':'OUI'}
		}
	},
	methods: {
		getDeposit () {
			//let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'deposit/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.deposit = r.data.deposit

					this.$swal.close()


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getDeposit()
		}
	}
}
</script>
