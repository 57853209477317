<template>
	<section class="main-container">
		<h1>
			<router-link :to="'/immatriculation/'+person.id" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Sa fiche
			</router-link>
			&nbsp;
			Gestion de documents
			<i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{person.firstname}} {{person.lastname}}</span>
		</h1>

		<div class="row">
			<div class="col-3">
				<img :src="person.photo" class="id_card_photo">
			</div>
			<div class="col-9">
				<h3>Ajouter un document</h3>
				<br>
				<form method="post" enctype="multipart/form-data">
					<div class="row">
						<div class="col-3 form-group">
							<label for="documentType">Type de document</label>
							<br>
							<select class="form-control" v-model="documentType" id="documentType">
								<option value="">Sélectionnez</option>
								<option value="Fichier unique">Fichier unique</option>
								<option value="Certificat de nationalité ivoirienne">Certificat de nationalité ivoirienne</option>
								<option value="Carte nationale d'identité">Carte nationale d'identité</option>
								<option value="Attestation d'identité">Attestation d'identité</option>
								<option value="Extrait d'acte de naissance">Extrait d'acte de naissance</option>
								<option value="Extrait du jugement supplétif">Extrait du jugement supplétif</option>
								<option value="Extrait d'acte de mariage">Extrait d'acte de mariage</option>
								<option value="Photocopie de livret de famille">Photocopie de livret de famille</option>
								<option value="Certificat de mariage">Certificat de mariage</option>
								<option value="Carte de séjour">Carte de séjour</option>
								<option value="Justificatif de résidence">Justificatif de résidence</option>
								<option value="Photocopie du passeport">Photocopie du passeport</option>
							</select>
						</div>
						<div class="col-9 form-group">
							<label for="documentType">Sélectionnez le document</label>
							<br>
							<input type="file" id="document" class="form-control">
						</div>
					</div>
					<br>
					<div class="form-group">
						<a href="" class="btn btn-primary" @click.prevent="uploadDocument()">
							<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
							Enregistrer
						</a>
					</div>
				</form>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h4>Liste des documents fournis</h4>
					</div>
					<div class="card-body">
						<ul v-if="documents && documents.length > 0">
							<li v-for="(d, k) in documents" :key="k">
								<strong>{{d.type}}</strong> [<a :href="d.link" class="" target="_blank">Télécharger</a>]
							</li>
						</ul>
						<div class="alert alert-danger" role="alert" v-else>
							Aucun document enregistré.
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Documents',
	components: { },
	data () {
		return {
			uid: null,
			person: null,
			documentType: "Fichier unique",
			documents: null
		}
	},
	methods: {
		uploadDocument () {
			let vm = this
			let fichier = document.querySelector('#document').files[0]

			if (!fichier) {
				this.$swal('Nom', "Vous devez sélectionner un fichier.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Téléversement du document en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let datas = new FormData()

				datas.append('document', fichier)
				datas.append('type', this.documentType)
				
				axios({
					method: 'POST',
					headers: this.$root.authHeaderFormData(),
					url: '/registration/document/' + this.uid,
					data: datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						//let w = (this.uid == null) ? 'créé' : 'modifié'
						//this.$swal("C'est fait", "Le document a bien été ajouté.", 'success')
						//document.location.href = this.$root.redirectUri +'/immatriculation/documents/'+ r.data.person
						this.$swal({
                            title: 'Le document a bien été ajouté.',
                            text: 'Que voulez-vous faire maintenant ?',
                            type: 'success',
                            showCancelButton: true,
                            cancelButtonText: 'Nouvelle inscription',
                            confirmButtonText: 'Voir la fiche',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                document.location.href = this.$root.redirectUri +'/immatriculation/'+ this.uid
                            } else {
                                document.location.href = this.$root.redirectUri +'/immatriculation/new/'
                            }
                        })

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function () {
					vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
				
			}
		},
		getPerson () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.person = r.data.person

					this.$swal.close()

					this.getDocuments()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		getDocuments () {
			let vm = this
			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/documents/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.documents = r.data.documents.items


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}
	}
}
</script>
