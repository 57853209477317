<template>
    <div class="action-buttons">
        <button class="btn btn-outline-primary" @click="handleAction('voucher')">
            <i class="fa fa-print"></i>
            &nbsp;&nbsp;
            Reçu
        </button>
    </div>
</template>
<script>

import axios from 'axios'    
export default {
    name: "ActionButtons",
    methods: {
        handleAction(actionName) {
            
            switch(actionName){
                case('voucher'):
                    
                    var vm = this

                    this.interceptor = axios.interceptors.request.use((config) => {
                        this.$swal('Veuillez patienter', 'Chargement du reçu...', 'info')
                            return config
                    }, (error) => {
                        return Promise.reject(error)
                    })

                    axios({
                        method: 'GET',
                        headers: this.$root.authHeader(),
                        url: 'deed/receipt/'+this.data.id,
                        responseType: 'json'
                    })
                    .then(r => {    
                        if (r.data.state == 'success') {
                            window.open(r.data.receipt)

                            this.$swal.close()

                        }else{
                            this.$swal('Oups !', r.data.message, 'error')
                        }
                    })
                    .catch(function (e) {
                        console.log(e)
                        console.log(vm)
                        //vm.$root.relogin()
                    })
                    axios.interceptors.request.eject(this.interceptor)
                    
                break;
            }
        }
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
};
</script>