<template>
    <div class="">
        <span>{{data.arrival_date | moment("DD/MM/YYYY")}}</span>
    </div>
</template>
<script>
export default {
    name: "ArrivalDateInDatatable",
    methods: {
        
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    }
};
</script>