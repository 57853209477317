export default [
	{
		"service": "Service État civil",
		"actes": [
			"Acte d'individualité",
			"Attestation de bonne conduite",
			"Attestation d'identité",
			"Attestation d'immatriculation",
			"Attestation de résidence",
			"Autorisation parentale",
			"Autorisation de transfert de dépouille",
			"Autre attestations consulaires",
			"Certificat de capacité matrimonial",
			"Certificat de célibat",
			"Certificat de coutume",
			"Certificat de déclaration de perte",
			"Certificat d'hébergement",
			"Certificat de résidence",
			"Copie intégrale d'acte de décès",
			"Copie intégrale d'acte de mariage",
			"Copie intégrale d'acte de naissance",
			"Copie intégrale de reconnaissance",
			"Décision d'adoption",
			"Décision de divorce",
			"Décision de modification de nom",
			"Extrait d'acte de décès",
			"Extrait d'acte de naissance",
			"Extrait d'acte de mariage ou certificat de mariage",
			"Fiche individuelle d'état civil",
			"Fiche familiale de mariage",
			"Fiche individuelle de décès",
			"Pacte de solidarité civil (PACS)"
		]
	},
	{
		"service": "Service Immatriculation",
		"actes": [
			"Attestation d'immatriculation"
		]
	},
	{
		"service": "Service Passeport biométrique et séjour",
		"actes": [
			"Passeport biométrique"
		]
	},
	{
		"service": "Service Social",
		"actes": []
	},
	{
		"service": "Service actes notariés",
		"actes": [
			"Certificat d'hérédité",
			"Certificat de propriété de titre nominatif",
			"Changement de régime matrimonial",
			"Clause de partage inégal de communauté",
			"Communauté d'acquêts",
			"Décharge de la responsabilité",
			"Déclaration de choix de la loi applicable antérieur au mariage",
			"Déclaration de choix de la loi applicable au cours du mariage",
			"Dépôt de testament après la mort du testateur",
			"Donation entre époux",
			"Donation entre époux pendant le mariage : Donation alternative",
			"Donation entre époux pendant le mariage : En toute propriété",
			"Donation entre époux pendant le mariage : En usufruit",
			"Mention de changement de régime",
			"Notoriété - Abandon d'un conjoint",
			"Notoriété - Absence d'héritiers réservataires",
			"Notoriété complexe - rectification d'erreur de prénom",
			"Notoriété - conjoint survivant donataire ou légataire. Enfants ou ascendants. Hypothèses divers",
			"Procuration à un mandataire non présent",
			"Procuration donnée à deux mandataires",
			"Procuration générale",
			"Procuration générale manuscrite",
			"Procuration par deux mandats à un mandataire présent et acceptant",
			"Procuration pour accepter une donation entre vifs",
			"Procuration pour acquérir",
			"Procuration pour emprunter",
			"Procuration pour faire une donation entre vifs",
			"Procuration pour participer à un conseil de famille",
			"Procuration pour recueillir une succession",
			"Procuration pour se rendre caution",
			"Procuration pour vendre",
			"Procuration spéciale",
			"Procuration spéciale manuscrite",
			"Révocation de procuration",
			"Révocation de testament",
			"Séparation de biens",
			"Substitution de mandataire, substitution générale",
			"Testament authentique",
			"Testament international",
			"Testament mystique",
			"Testament olographe"
		]
	},
	{
		"service": "Service Visas biométriques",
		"actes": [
			"Visas biométriques"
		]
	},
	{
		"service": "Service Visas spéciaux",
		"actes": [
			"Visas de courtoisie",
			"Visas diplomatiques",
			"Visas gratis",
			"Visas militaires",
			"Visas service"
		]
	},
	{
		"service": "Service Des associations ivoiriennes",
		"actes": []
	},
	{
		"service": "Service Légalisation",
		"actes": [
			"Authentification de permis de conduire",
			"Authentification des actes de l'état civil",
			"Certifié conforme à l'originale",
			"Légalisation des actes publics",
			"Légalisation des actes sous seing privé"
		]
	},
	{
		"service": "Service Personnes de passage et dépôts",
		"actes": []
	}
]
