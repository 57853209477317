<template>
	<section class="main-container">
		<div class="card-deck">
			<div class="card text-white bg-success mb-3" style="max-width: 18rem;">
				<div class="card-header upper bold">Personnes immatriculées</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.total}}</h5>
					<p class="card-text">Depuis le début</p>
				</div>
			</div>

			<div class="card text-white bg-primary mb-3" style="max-width: 18rem;">
				<div class="card-header upper bold">Hommes</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.men}}</h5>
					<p class="card-text" v-if="stats.total > 0">Soit {{((stats.men/stats.total)*100).toFixed(2)}}%</p>
				</div>
			</div>
			
			<div class="card text-white bg-danger mb-3" style="max-width: 18rem;">
				<div class="card-header upper bold">Femmes</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.women}}</h5>
					<p class="card-text" v-if="stats.total > 0">Soit {{((stats.women/stats.total)*100).toFixed(2)}}%</p>
				</div>
			</div>
			
			<div class="card text-white bg-warning mb-3" style="max-width: 18rem;">
				<div class="card-header upper bold">Mineurs</div>
				<div class="card-body">
					<h5 class="card-title stat-number white">{{stats.young}}</h5>
					<p class="card-text" v-if="stats.total > 0">Soit {{((stats.young/stats.total)*100).toFixed(2)}}%</p>
				</div>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-7">
				<h3 class="center">Immatriculations de cette semaine</h3>
				<ve-line :data="chartData"></ve-line>
			</div>
			<div class="col-5">
				<h3 class="center">Proportion de cette semaine</h3>
				<ve-pie :data="pieData"></ve-pie>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from 'axios'
	import VeLine from 'v-charts/lib/line.common'
	import VePie from 'v-charts/lib/pie.common'
	export default {
		name: 'Home',
		components: { VeLine, VePie },
		data () {
			return {
				stats: {
					"total": 0,
					"men": 0,
					"women": 0,
					"young": 0,
					"stats": [
						{
							"date": "13-02-2022",
							"count": 0
						}
					]
				},
				chartData: {
					columns: ['date', 'Immatriculations'],
					rows: []
				},
				pieData: {
					columns: ['genre', 'Immatriculations'],
					rows: [
						{ 'genre': 'Hommes', 'Immatriculations': 0 },
						{ 'genre': 'Femmes', 'Immatriculations': 0 },
						{ 'genre': 'Mineurs', 'Immatriculations': 0 }
					]
				}
			}
		},
		methods: {
			getStats() {
				axios({
					method: 'GET',
					headers: this.$root.authHeader(),
					url: 'dashboard',
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						this.stats = r.data.data
						
						this.pieData.rows[0].Immatriculations = this.stats.men
						this.pieData.rows[1].Immatriculations = this.stats.women
						this.pieData.rows[2].Immatriculations = this.stats.young

						for(let i=0; i<this.stats.stats.length; i++){
							this.chartData.rows.push({ 'date': this.stats.stats[i].date, 'Immatriculations': this.stats.stats[i].count })
						}

					}else{
						console.log(r.data.message)
					}
				})
			}

		},
		created () {
			this.getStats()
		}
	}
</script>
