<template>
	<section class="main-container">
		<h1>
			<router-link to="/messagerie" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-envelope"></i>
				Messagerie
			</router-link>
			&nbsp;
			Boîte d'envoi de mails
		</h1>

		<div class="row">
			<div class="col-12">
				<form>
					<div class="row">
						<div class="form-group col-3">
							<label for="name">Nom & Prénoms</label>
							<input type="text" name="name" id="name" class="form-control" v-model="search.name">
						</div>
						<div class="form-group col-3">
							<label for="subject">Objet / Message</label>
							<input type="text" name="subject" id="subject" class="form-control" v-model="search.subject">
						</div>
						<div class="form-group col-2">
							<label for="from">Du</label>
							<input type="date" name="from" id="from" class="form-control" v-model="search.from">
						</div>
						<div class="form-group col-2">
							<label for="to">Au</label>
							<input type="date" name="to" id="to" class="form-control" v-model="search.to">
						</div>
						<div class="form-group col-2">
							<label>&nbsp;</label>
							<button class="form-control btn btn-primary" @click.prevent="getPersons()">
								Rechercher
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-5">
				<div>
					<h5 v-if="messages.length > 0">{{messages.length}} résultats</h5>
					<ul class="list-group selectorList" v-if="messages.length > 0">
						<li class="list-group-item toSelect" v-for="p in messages" :key="p.id" :data-id="p.id" :class="(msg.id == p.id) ? 'on':''">
							<div class="row">
								<div class="col-12">
									<span class="bold">{{p.name}}</span> <br>
									<span class="mail-date">{{p.created_at.date | moment("DD-MM-YYYY")}}</span>
									<p>{{p.subject}}</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-7" v-if="msg != null">
				<h3>{{msg.subject}}</h3>
				<span class="mail-date">{{p.created_at.date | moment("DD-MM-YYYY HH:mm")}}</span>
				<br>
				<p>
					{{msg.message}}
				</p>

				<div class="card" v-if="msg.attachments.items.length > 0">
					<div class="card-header">
						<h4>Pièces jointes</h4>
					</div>
					<div class="card-body">
						<ul>
							<li v-for="(d, k) in msg.attachments.items" :key="k">
								<a :href="d.file" class="" target="_blank">
									<strong>{{d.name}}</strong>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>

import axios from 'axios'
export default {
	name: 'Messagerie',
	components: { },
	data () {
		var todayDate = this.$root.YmdDate()
		return {
			messages: [],
			search: {
				name: '',
				email: '',
				subject: '',
				from: '',
				to: todayDate
			},
			msg: null
		}
	},
	methods: {
		getMessages () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des messages...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'emails',
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.messages = r.data.mailbox.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				console.log(e)
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
    
	},
	created () {
		//this.getMessages()
	}
}
</script>
