<template>
	<section class="main-container">
		<h1>
			<router-link to="/associations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Associations
			</router-link>
			&nbsp;
			Fiche d'enregistrement
			<!--i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{association.corporate_name}}</span-->
		</h1>

		<div class="row">
			<div class="col-3">
				<div class="center">
					<img :src="association.logo" class="id_card_photo">
				</div>
			</div>
			<div class="col-9">
				<div class="top_right">
					<a :href="'./association/edit/'+association.id" class="btn btn-warning upper">
						<i class="fa fa-edit"></i>&nbsp;
						Modifier
					</a>
				</div>
				<h2>
					N° <span class="text-primary">{{association.registration_number}}</span>
					&nbsp;
					<i class="fa fa-angle-double-right"></i>
					&nbsp;
					<span class="green">{{association.corporate_name}}</span>
				</h2>
			</div>
		</div>
		<br><br>
		<div :class="(!overlay) ? 'overlay closed' : 'overlay'"></div>
		<aside :class="(!aside) ? 'closed' : ''">
			<div>

				<div class="row" v-if="addResponsible">
					<h3>Ajouter un dirigeant</h3>
					<br>
					<form>
						<div class="row">
							<div class="form-group col-6">
								<label for="nom">Nom *</label>
								<input type="text" class="form-control upper" id="nom" v-model="responsible.lastname">
							</div>
							<div class="form-group col-6">
								<label for="prenom">Prénoms *</label>
								<input type="text" class="form-control upper" id="prenom" v-model="responsible.firstname">
							</div>
						</div>
						<div class="row">
							<div class="form-group col-12">
								<label for="office">Poste *</label>
								<input type="text" class="form-control" id="office" v-model="responsible.office">
							</div>
						</div>

						<div class="form-group">
							<a href="" class="btn btn-primary" @click.prevent="saveResponsible()">
								<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
								Enregistrer
							</a>
							&nbsp;&nbsp;&nbsp;
							<a href="" class="btn btn-secondary upper" @click.prevent="toggleAside()">
								<i class="fa fa-delete"></i>&nbsp;
								Annuler
							</a>
						</div>
					</form>
				</div>

				<div class="row" v-if="addResult">
					<h3>Ajouter un bilan</h3>
					<br>
					<form>
						<div class="row">
							<div class="form-group col-3">
								<label for="year">Année *</label>
								<input type="number" class="form-control upper" id="year" v-model="result.year">
							</div>
						</div>
						<div class="row">
							<div class="form-group col-12">
								<label for="report">Bilan *</label>
								<textarea type="text" class="form-control" id="report" v-model="result.report"></textarea>
							</div>
						</div>

						<div class="form-group">
							<a href="" class="btn btn-primary" @click.prevent="saveResult()">
								<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
								Enregistrer
							</a>
							&nbsp;&nbsp;&nbsp;
							<a href="" class="btn btn-secondary upper" @click.prevent="toggleAside()">
								<i class="fa fa-delete"></i>&nbsp;
								Annuler
							</a>
						</div>
					</form>
				</div>
			</div>
		</aside>

		<div class="row">
			<div class="col-8">
				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Informations de l'association</h3>
					</div>
					<div class="card-body text-dark">
						<div class="row">
							<div class="col-12">
								<div>
									<div>
										<div class="row">
											<div class="col-4">
												<strong>Dénomination sociale</strong>
												<br>
												<span>{{association.corporate_name}}</span>
											</div>
											<div class="col-4">
												<strong>Nature</strong>
												<br>
												<span>{{association.nature.replace(',', ', ')}}</span>
											</div>
											<div class="col-4">
												<strong>Catégorie</strong>
												<br>
												<span>{{association.category.replace(',', ', ')}}</span>
											</div>
										</div>
										<br>
										<div class="row">
											<div class="col-4">
												<strong>Date de création</strong>	
												<br>
												<span>{{association.creation_date.date | moment("DD/MM/YYYY")}}</span>
											</div>
											<div class="col-4">
												<strong>Lieu de création</strong>	
												<br>
												<span>{{association.place_creation}}</span>
											</div>
											<div class="col-4">
												<strong>Nationalité</strong>	
												<br>
												<span>{{association.nationality}}</span>
											</div>
										</div>
									</div>
								</div>
								<br>
								<div>
									<h5 class="card-title text-primary">Adresse</h5>

									<div class="row">
										<div class="col-4">
											<strong>Adresse</strong>
											<br>
											<span>{{association.address}}</span>
										</div>
										<div class="col-4">
											<strong>Ville/État</strong>
											<br>
											<span>{{association.city}}</span>
											&nbsp;
											<span>{{association.state}}</span>
										</div>
										<div class="col-4">
											<strong>Code postal</strong>
											<br>
											<span>{{association.postal_code}}</span>
										</div>
									</div>
									<br>
									<div class="row">
										<div class="col-6">
											<strong>Téléphone</strong>
											<br>
											<span>{{association.phone}}</span>
										</div>
										<div class="col-6">
											<strong>Email</strong>
											<br>
											<span>{{association.email}}</span>
										</div>
									</div>
								</div>
								<br>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-4">
				<div class="card">
					<div class="card-header bg-info">
						<h5>DIRIGEANTS</h5>
					</div>
					<div class="card-body">
						<ul class="list-group list-group-flush" v-if="responsibles">
							<li class="list-group-item" v-for="r in responsibles" :key="r.id">
								<b>{{r.lastname}} {{r.firstname}}</b>
								<br>
								<i>{{r.office}}</i>
							</li>
						</ul>
						<div class="center">
							<a :href="'./association/leader/'+association.id" class="btn btn-warning upper" @click.prevent="toggleAside('d')">
								<i class="fa fa-plus"></i>&nbsp;
								Ajouter un dirigeant
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<br><br>
		<div class="row">
			<div class="col-12">
				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>
							Bilans

							<a :href="'./association/bilan/'+association.id" class="btn btn-warning upper" @click.prevent="toggleAside('b')">
								<i class="fa fa-plus"></i>&nbsp;
								Ajouter
							</a>
						</h3>
					</div>
					<div class="card-body text-dark">
						<div v-if="results">
							<div class="card bilan" v-for="r in results" :key="r.id">
								<div class="card-body">
									<h5 class="card-title">{{r.year}}</h5>
									<p>{{r.report}}</p>
									<a href="#" class="btn btn-primary">Modifier</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Documents',
	components: { },
	props: {
		visible: {
			type: Boolean,
			required: false,
			default: false
		},
		resizeWidth:{
			type:Object,
		},
		animationPanel:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		animationParent:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		bgOverlay:{
			type: String,
			required: false,
			default: '#fff'
		},
		bgPanel:{
			type: String,
			required: false,
			default: '#fff'
		},
		defaultWidth:{
			type: String,
			required: false,
			default: '50%'
		},
		closeScroll:{
			type: Boolean,
			required: false,
			default: true
		}
    },
	data () {
		return {
			open: false,
			uid: null,
			did: null,
			bid: null,
			association: null,
			responsibles: null,
			results: null,
			yesOrNo: {'false':'NON', 'true':'OUI'},
			aside: false,
			overlay: false,
			addResponsible: false,
			addResult: false,
			responsible: {
				"lastname": "",
				"firstname": "",
				"office": ""
			},
			result: {
				"year": "",
				"report": ""
			}
		}
	},
	methods: {
		toggleAside(obj){
			if(!this.aside){
				this.aside = true
				this.overlay = true
			}
			else {
				this.aside = false
				this.overlay = false
			}

			// ajout de dirigeant
			if(obj == 'd')
				this.addResponsible = true

			else if(obj == 'b')
				this.addResult = true

			else{
				this.addResponsible = false
				this.addResult = false
			}
			
		},
		saveResponsible () {
			if (this.responsible.lastname == null) {
				this.$swal('Nom', "Vous devez saisir le nom du dirigeant.", 'warning')
				return !1

			} else if (this.responsible.firstname == null) {
				this.$swal('Prénom', "Vous devez saisir le prénom du dirigeant.", 'warning')
				return !1

			} else if (this.responsible.office == null) {
				this.$swal('Type de pièce', "Vous devez renseigner le poste du dirigeant.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement du dirigeant en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.did == null) ? 'POST' : 'PUT'
				let requestUrl = (this.did == null) ? 'association/manager/'+this.uid : 'association/manager/'+this.did

				axios({
					method: requestMethod,
					headers: this.$root.authHeader(),
					url: requestUrl,
					data: {
						'lastname' : this.responsible.lastname,
						'firstname' : this.responsible.firstname,
						'office' : this.responsible.office
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.did == null) ? 'enregistré' : 'modifié'
						this.$swal("C'est fait", 'Le dirigeant a bien été '+w+'.', 'success')

						if(this.did == null){
							this.responsible = {
								"lastname": "",
								"firstname": "",
								"office": ""
							}
						}

						this.getResponsibles()
						this.toggleAside()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		saveResult () {
			if (this.result.year == null) {
				this.$swal('Année', "Vous devez préciser l'année du bilan.", 'warning')
				return !1

			} else if (this.result.report == null) {
				this.$swal('Bilan', "Vous devez saisir le bilan.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement du bilan en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.bid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.bid == null) ? 'association/report/'+this.uid : 'association/report/'+this.bid

				axios({
					method: requestMethod,
					headers: this.$root.authHeader(),
					url: requestUrl,
					data: {
						'year' : this.result.year,
						'report' : this.result.report
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.bid == null) ? 'enregistré' : 'modifié'
						this.$swal("C'est fait", 'Le bilan a bien été '+w+'.', 'success')

						if(this.bid == null){
							this.result = {
								"year": "",
								"report": ""
							}
						}

						this.getResults()
						this.toggleAside()

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getAssociation () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'association/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.association = r.data.association

					this.getResponsibles()
					this.getResults()

					this.$swal.close()


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		getResponsibles () {
			let vm = this

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'association/managers/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.responsibles = r.data.managers.items

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		getResults () {
			let vm = this

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'association/reports/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.results = r.data.reports.items

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getAssociation()
		}
	}
}
</script>
