<template>
	<section class="main-container">
		<h1>
			<router-link to="/passages" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Personnes de passage
			</router-link>
			&nbsp;
			<!--Consulter un dépôt-->
			<i class="fa fa-angle-double-right"></i>
			&nbsp;
			<span class="green">{{transient.lastname}} {{transient.firstname}}</span>
		</h1>

		<div class="row">
			<div class="col-12">
				<div class="top_right">
					<a :href="'./edit/'+transient.id" class="btn btn-warning upper">
						<i class="fa fa-edit"></i>&nbsp;
						Modifier
					</a>
					&nbsp;&nbsp;&nbsp;
					<button class="btn btn-danger upper">
						Supprimer
					</button>
				</div>
				<h2>
					<span class="">Arrivé(e) le {{transient.arrival_date | moment("DD/MM/YYYY")}}</span>
				</h2>
			</div>
		</div>
		<br><br>
		<div class="row">
			<div class="col-12">
				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Informations sur le passage</h3>
					</div>
					<div class="card-body text-dark">
						<div class="row">
							<div class="col-12">
								<div>
									<div>
										<div class="row">
											<div class="col-4">
												<strong>Personne de passage</strong>
												<br>
												<span>{{transient.lastname}} {{transient.firstname}}</span>
											</div>
											<div class="col-4">
												<strong>Profession</strong>
												<br>
												<span>{{transient.profession}}</span>
											</div>
										</div>
										<br>
										<div class="row">
											<div class="col-4">
												<strong>Date d'arrivée</strong>	
												<br>
												<span>{{transient.arrival_date | moment("DD/MM/YYYY")}}</span>
											</div>
											<div class="col-4">
												<strong>Date de départ</strong>	
												<br>
												<span>{{transient.departure_date | moment("DD/MM/YYYY")}}</span>
											</div>
										</div>
									</div>
								</div>
								<br>
								<div v-if="transient.contacts != null">
									<h5 class="card-title text-primary">Contacts USA</h5>

									<div class="row">
										<div class="col-3">
											<strong>Adresse</strong>
											<br>
											<span>{{transient.contacts.contact_usa.address}}</span>
										</div>
										<div class="col-3">
											<strong>Ville / État</strong>
											<br>
											<span>{{transient.contacts.contact_usa.city}}, {{transient.contacts.contact_usa.state}}</span>
										</div>
										<div class="col-3">
											<strong>Code postal</strong>
											<br>
											<span>{{transient.contacts.contact_usa.postcode}}</span>
										</div>
										<div class="col-3">
											<strong>Téléphone</strong>
											<br>
											<span>{{transient.contacts.contact_usa.phone}}</span>
										</div>
									</div>
								</div>
								<br>
								<div v-if="transient.contacts != null">
									<h5 class="card-title text-primary">Contacts Côte d'Ivoire</h5>

									<div class="row">
										<div class="col-3">
											<strong>Adresse</strong>
											<br>
											<span>{{transient.contacts.contact_ci.address}}</span>
										</div>
										<div class="col-3">
											<strong>Ville</strong>
											<br>
											<span>{{transient.contacts.contact_ci.city}}</span>
										</div>
										<div class="col-3">
											<strong>Téléphone</strong>
											<br>
											<span>{{transient.contacts.contact_ci.phone}}</span>
										</div>
									</div>
								</div>
								<br>
								<div class="row" v-if="transient.contacts != null">
									<div class="col-4">
										<strong>Adresse Email</strong>
										<br>
										<span>{{transient.contacts.email}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card border-dark mb-3">
					<div class="card-header">
						<h3>Commentaire</h3>
					</div>
					<div class="card-body text-dark">
						<div class="row">
							<div class="col-12">
								<div>
									<p>{{transient.comment}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'

export default {
	name: 'Transient',
	components: { },
	props: {
		visible: {
			type: Boolean,
			required: false,
			default: false
		},
		resizeWidth:{
			type:Object,
		},
		animationPanel:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		animationParent:{
			type: String,
			required: false,
			default: 'modal-fade'
		},
		bgOverlay:{
			type: String,
			required: false,
			default: '#fff'
		},
		bgPanel:{
			type: String,
			required: false,
			default: '#fff'
		},
		defaultWidth:{
			type: String,
			required: false,
			default: '50%'
		},
		closeScroll:{
			type: Boolean,
			required: false,
			default: true
		}
    },
	data () {
		return {
			open: false,
			uid: null,
			transient: null,
			yesOrNo: {'false':'NON', 'true':'OUI'}
		}
	},
	methods: {
		getTransient () {
			//let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'transient/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.transient = r.data.transient

					this.$swal.close()


				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getTransient()
		}
	}
}
</script>
