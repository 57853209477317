<template>
	<section class="main-container">
		<h1>
			<router-link to="/acts" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Registre des actes
			</router-link>
			&nbsp;
			Enregistrer une demande d'acte
		</h1>

		<div class="row">
			<div class="col-9">
				<form>
					<div class="row">
						<div class="form-group col-3">
							<label for="number">N° d'inscription</label>
							<input type="text" name="number" id="number" class="form-control" v-model="search.number">
						</div>
						<div class="form-group col-6">
							<label for="name">ou Nom & Prénoms</label>
							<input type="text" name="name" id="name" class="form-control" v-model="search.name">
						</div>
						<div class="form-group col-3">
							<label>&nbsp;</label>
							<button class="form-control btn btn-primary" @click.prevent="getPersons()">
								Rechercher
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-4">
				<div>
					<h5 v-if="persons.length > 0">{{persons.length}} résultats</h5>
					<ul class="list-group selectorList" v-if="persons.length > 0">
						<li class="list-group-item toSelect" v-for="p in persons" :key="p.id" :data-id="p.id" @click="selectPerson(p.id)" :class="(person == p.id) ? 'on':''">
							<div class="row">
								<div class="col-2">
									<img :src="p.photo" class="id_card_photo thumbnail">
								</div>
								<div class="col-10">
									<span class="bold">{{p.firstname}} {{p.lastname}}</span> <br>
									<span>Né(e) le {{p.birthday | moment("DD/MM/YYYY")}} à {{p.birthplace}}</span> <br>
									<span>N° {{p.registration_number}}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-8" v-if="person != null">
				<h3>ENREGISTRER UNE DEMANDE</h3>
				<form>
					<div class="row">
						<div class="form-group col-5">
							<label for="service">Service *</label>
							<select type="text" name="service" id="service" class="form-control" v-model="service" @change="selectService(s)">
								<option value="">-- Choisissez --</option>
								<option :value="s.service" v-for="s in services" :key="s.service">
									{{s.service}}
								</option>
							</select>
						</div>
						<div class="form-group col-7">
							<label for="act">Acte *</label>
							<select type="text" name="act" id="act" class="form-control" v-model="act">
								<option value="">-- Choisissez --</option>
								<optgroup :label="s.service" v-for="s in services" :key="s.service" :class="(service != s.service) ? 'hide' : ''">
									
									<option :value="a" v-for="a in s.actes" :key="a">
										{{a}}
									</option>
								</optgroup>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="form-group col-5">
							<label for="chancellery_right">Droit de chancellerie *</label>

							<div class="input-group mb-3">
								<div class="input-group-prepend">
									<span class="input-group-text">$</span>
								</div>
								<input type="number" class="form-control" name="chancellery_right" id="chancellery_right" v-model="chancellery_right">
								<div class="input-group-append">
									<span class="input-group-text">.00</span>
								</div>
							</div>

							<!--input type="number" name="chancellery_right" id="chancellery_right" class="form-control" v-model="chancellery_right"-->
						</div>
						<div class="form-group col-7">
							<label for="code">Code</label>
							<input type="text" name="code" id="code" class="form-control" v-model="code">
						</div>
					</div>
					<button class="btn btn-primary" @click.prevent="saveData()">Enregistrer</button>
				</form>
				<br>
				<div class="card">
					<div class="card-header">
						<h4>Liste des actes demandés</h4>
					</div>
					<div class="card-body">
						<!--ul v-if="acts && acts != null">
							<li v-for="(d, k) in documents" :key="k">
								<strong>{{d.type}}</strong> [<a :href="d.link" class="" target="_blank">Télécharger</a>]
							</li>
						</ul-->
						<div v-if="acts && acts != null">
							<data-table v-bind="parameters" />

							<download-excel
								class="btn btn-warning"
								:data="deposits"
								:fields="json_fields"
								worksheet="Demandes d'actes"
								name="actes-demandes.xls"
							>
								Télécharger .XLS
							</download-excel>
						</div>
						<div class="alert alert-danger" role="alert" v-else>
							Aucune demande enregistrée.
						</div>
					</div>
				</div>
			</div>
		</div>

	</section>
</template>

<script>

import axios from 'axios'
import downloadExcel from "vue-json-excel"
import Services from "../../components/Acts/services.js"
import ActionsButtons from '@/components/Acts/ActionsButtons'
import CreatedDateInDatatable from '@/components/CreatedDateInDatatable'
export default {
	name: 'NewAct',
	components: { downloadExcel },
	data () {
		return {
			person: null,
			persons: [],
			acts: null,
			services: Services,
			selectedService: null,
			actesToDisplay: [],
			search: {
				number: "",
				name: "",
				from: "",
				to: ""
			},
			service: "",
			act: "",
			chancellery_right: "",
			code: "",
			json_fields: {
				"Service": "service",
				"Acte": "name",
				"Droit de chancellerie": "cost",
				"Code": "code",
				"Demandeur": "person.lastname",
				"Date": "created_at.date",
			}
		}
	},
	computed: {
		parameters() {
			return {
				text: {
					PerPageText: "Nombre de demandes par page :entries",
					infoText: " :first à :last sur :total ",
					infoTextFiltered: "Vue de :first à :last sur :filtered (résultats de :total demandes)",
					emptyTableText: "Aucune donnée trouvée",
					nextButtonText: "Suiv.",
					previousButtonText: "Préc.",
					paginationSearchText: "Aller à la page",
					paginationSearchButtonText: "Aller",
					searchText: "Recherche:",
					downloadText: "Exporter en:",
					downloadButtonText: "OK"
				},
				perPageSizes: [10, 25, 50, 100, 500, 1000, 5000, 10000, 50000],
				defaultPerPage: 10,
				perPageText: 'Afficher',
				lang: 'fr_fr',
				data: this.acts,
				showDownloadButton: false,
				columns: [
					{
						key: "service",
						title: "Service"
					},
					{
						key: "name",
						title: "Acte"
					},
					{
						key: "cost",
						title: "Coût"
					},
					{
						key: "code",
						title: "Code"
					},
					{
						key: "created_at.date",
						title: "Enregistrée le",
						component: CreatedDateInDatatable
					},
					{
						key: "actions",
						sortable: false,
						component: ActionsButtons
					}
				]
			}
		}
    },
	methods: {
		selectService(s){
			this.actesToDisplay = this.services[s].actes
		},
		selectPerson(_id){
			this.person = _id

			if(this.person){
				//console.log(this.person)
				this.getActs()
			}
		},
		getPersons () {
			let vm = this

			let datas = ""

			if(this.search.number != "")
				datas += "number="+this.search.number

			if(this.search.name != "")
				datas += "&name="+this.search.name

			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des individus...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registrations?'+datas,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.persons = r.data.data.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		},
		saveData () {
			if (this.service == null) {
				this.$swal('Service', "Vous devez sélectionner le service.", 'warning')
				return !1

			} else if (this.act == null) {
				this.$swal('Acte', "Vous devez sélectionner l'acte.", 'warning')
				return !1

			} else if (this.chancellery_right == null) {
				this.$swal('Droit de chancellerie', "Vous devez saisir le droit de chancellerie.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				axios({
					method: 'POST',
					headers: this.$root.authHeader(),
					url: 'deed/'+this.person,
					data: {
						'name' : this.act,
						'code' : this.code,
						'service' : this.service,
						'cost' : this.chancellery_right
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.uid == null) ? 'enregistrée' : 'modifiée'
						this.$swal("C'est fait", 'La demande a bien été '+w+'.', 'success')


					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getActs () {

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'deeds?person='+this.person,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					this.acts = r.data.deeds.items
					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				
			})
		}
	},
	created () {
		//console.log(this.services)
	}
}
</script>
