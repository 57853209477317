<template>
	<section class="main-container">
		<h1>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Immatriculations
			</router-link>
			&nbsp;
			<i class="fa fa-pie-chart"></i>
			Statistiques
		</h1>

		<form>
			<div class="row">
				<div class="form-group col-2">
					<label for="gender">Genre</label>
					<select class="form-control" id="gender" v-model="stats.gender">
						<option value="">Choisissez</option>
						<option value="m">Hommes</option>
						<option value="f">Femmes</option>
					</select>
				</div>
				<div class="form-group col-2">
					<label for="min_age">Âge minimum</label>
					<input type="number" name="min_age" id="min_age" placeholder="0" class="form-control" v-model="stats.min_age">
				</div>
				<div class="form-group col-2">
					<label for="max_age">Âge maximun</label>
					<input type="number" name="max_age" id="max_age" placeholder="100" class="form-control" v-model="stats.max_age">
				</div>
				<div class="form-group col-2">
					<label>ou âge (précis)</label>
					<select class="form-control" v-model="stats.age">
						<option value="">Tous</option>
						<option :value="age" v-for="age in 100" :key="age">{{age}}</option>
					</select>
				</div>
				<div class="form-group col-2">
					<label for="from">Période du</label>
					<input type="date" name="from" id="from" class="form-control" v-model="stats.from">
				</div>
				<div class="form-group col-2">
					<label for="to">au</label>
					<input type="date" name="to" id="to" class="form-control" v-model="stats.to">
				</div>
			</div>
			<div class="row">
				<div class="form-group col-2">
					<label for="city">Ville</label>
					<input type="text" name="city" id="city" class="form-control" v-model="stats.city">
				</div>
				<div class="form-group col-2">
					<label for="state">État</label>
					<select name="state" id="state" class="form-control" v-model="stats.state">
						<option value="">Choisissez</option>
						<option 
							v-for="etat in etats"
							:key="etat"
							:value="etat">{{etat}}</option>
					</select>
				</div>
				<!--div class="form-group col-4">
					<label for="query">Nom, N° d'immatriculation</label>
					<input type="text" name="query" id="query" class="form-control" v-model="stats.query">
				</div-->
				<div class="form-group col-2">
					<label for="job">Profession</label>
					<input type="text" name="job" id="job" class="form-control" v-model="stats.job">
				</div>
				<div class="form-group col-2">
					<label>&nbsp;</label>
					<button class="form-control btn btn-primary" @click.prevent="search()">
						Rechercher
					</button>
				</div>
			</div>
			<div class="row">
			</div>
		</form>

		<br><br>
		<div class="row">
			<div class="col-6">
				<h3 class="center">Proportion</h3>
				<ve-pie :data="pieData"></ve-pie>
			</div>
			<div class="col-6">
				<h3 class="center">Cette semaine</h3>
				<ve-bar :data="chartData"></ve-bar>
			</div>
		</div>

		<br><br>
		<div class="row">
			<div class="col-12">
				<h3 class="center">Cette année</h3>
				<ve-line :data="yearData"></ve-line>
			</div>
		</div>
	</section>
</template>

<script>

import States from "./usa_states.json"
import { 
	use 
} from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { PieChart } from "echarts/charts"
import {
	GridComponent,
	PolarComponent,
	GeoComponent,
	TooltipComponent,
	LegendComponent,
	TitleComponent,
	VisualMapComponent,
	DatasetComponent,
	ToolboxComponent,
	DataZoomComponent
} from "echarts/components"
//import VChart, { THEME_KEY } from "vue-echarts"
import VeBar from 'v-charts/lib/bar.common'
import VeLine from 'v-charts/lib/line.common'
import VePie from 'v-charts/lib/pie.common'

use([
	PieChart,
	CanvasRenderer,
	GridComponent,
	TitleComponent,
	TooltipComponent,
	LegendComponent,
	DatasetComponent,
	PolarComponent,
	GeoComponent,
	VisualMapComponent,
	ToolboxComponent,
	DataZoomComponent
])

export default {
	name: 'Stats',
	components: { VeBar, VeLine, VePie },
	data() {
		//var today = new Date()
		var todayDate = this.$root.YmdDate()
		return {
			chartData: {
				columns: ['date', 'Immatriculations'],
				rows: [
					{ 'date': '01-01', 'Immatriculations': 1231 },
					{ 'date': '01-02', 'Immatriculations': 1223 },
					{ 'date': '01-03', 'Immatriculations': 2123 },
					{ 'date': '01-04', 'Immatriculations': 4123 },
					{ 'date': '01-05', 'Immatriculations': 3123 },
					{ 'date': '01-06', 'Immatriculations': 7123 }
				]
			},
			pieData: {
				columns: ['genre', 'Immatriculations'],
				rows: [
					{ 'genre': 'Hommes', 'Immatriculations': 1157 },
					{ 'genre': 'Femmes', 'Immatriculations': 1032 },
					{ 'genre': 'Mineurs', 'Immatriculations': 490 }
				]
			},
			yearData: {
				columns: ['date', 'Immatriculations'],
				rows: [
					{ 'date': '01-2021', 'Immatriculations': 1231 },
					{ 'date': '02-2021', 'Immatriculations': 1223 },
					{ 'date': '03-2021', 'Immatriculations': 2123 },
					{ 'date': '04-2021', 'Immatriculations': 4123 },
					{ 'date': '05-2021', 'Immatriculations': 3123 },
					{ 'date': '06-2021', 'Immatriculations': 3123 },
					{ 'date': '07-2021', 'Immatriculations': 3123 },
					{ 'date': '08-2021', 'Immatriculations': 3123 },
					{ 'date': '09-2021', 'Immatriculations': 3123 },
					{ 'date': '10-2021', 'Immatriculations': 3123 },
					{ 'date': '11-2021', 'Immatriculations': 3123 },
					{ 'date': '12-2021', 'Immatriculations': 7123 }
				]
			},
			periode: {
				from: '2021-01-01',
				to: todayDate
			},
			stats: {
				gender: "",
				age: "",
				min_age: 0,
				max_age: 100,
				city: "",
				job: "",
				state: "",
				query: "",
				from: null,
				to: null,
			},
			etats: States,
			option: {
				title: {
					text: "Proportion par genre",
					left: "center"
				},
				tooltip: {
					trigger: "item",
					formatter: "{a} <br/>{b} : {c} ({d}%)"
				},
				legend: {
					orient: "vertical",
					left: "left",
					data: [
						"Hommes",
						"Femmes"
					]
				},
				series: [
					{
						name: "Proportion par genre",
						type: "pie",
						radius: "55%",
						center: ["50%", "60%"],
						data: [
							{ value: 335, name: "Hommes" },
							{ value: 310, name: "Femmes" }
						],
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: "rgba(0, 0, 0, 0.5)"
							}
						}
					}
				]
			}
		}
    },
	methods: {
		search(){}
	},
	created () {
		this.stats.from = this.periode.from
		this.stats.to = this.periode.to
	}
}
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>
