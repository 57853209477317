<template>
	<section class="main-container">
		<h1 v-if="uid">
			<router-link to="/passages" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			Personne de passage
			<br>
			<small>{{person.lastname}} {{person.firstname}}</small>
		</h1>
		<h1 v-else>Enregistrer une personne de passage</h1>
		<a href="" class="reset_form" @click.prevent="resetForm()">Réinitialiser le formulaire</a>

		<div class="alert alert-info">
			Les champs marqués par une étoile (*) sont obligatoires.
		</div>

		<form>
			<fieldset>
				<legend>Informations sur la personne</legend>
				<div class="row">
					<div class="form-group col-6">
						<label for="lastname">Nom *</label>
						<input type="text" class="form-control upper" id="lastname" v-model="person.lastname" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="firstname">Prénoms *</label>
						<input type="text" class="form-control upper" id="firstname" v-model="person.firstname" @blur="updateLocalPerson()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<label for="profession">Profession *</label>
						<input type="text" class="form-control upper" id="profession" v-model="person.profession" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-3">
						<label for="arrival_date">Date d'arrivée *</label>
						<input type="date" class="form-control" id="arrival_date" v-model="person.arrival_date" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-3">
						<label for="departure_date">Date de départ *</label>
						<input type="date" class="form-control" id="departure_date" v-model="person.departure_date" @blur="updateLocalPerson()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-9">
						<label for="comment">Commentaire</label>
						<textarea class="form-control" id="comment" v-model="person.comment" @blur="updateLocalPerson()"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Contacts</legend>
				<div class="row">
					<div class="form-group col-6">
						<label for="tel_usa">Téléphone USA *</label>
						<input type="phone" class="form-control" id="tel_usa" v-model="person.contacts.contact_usa.phone" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="adresse_usa">Adresse USA *</label>
						<input type="text" class="form-control" id="adresse_usa" v-model="person.contacts.contact_usa.address" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="city_usa">Ville</label>
						<input type="text" class="form-control" id="city_usa" v-model="person.contacts.contact_usa.city" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="state_usa">État</label>
						<select id="state_usa" class="form-control" v-model="person.contacts.contact_usa.state" @change="updateLocalPerson()">
							<option value="">Choisissez</option>
							<option 
								v-for="etat in etats"
								:key="etat"
								:value="etat">{{etat}}</option>
						</select>
					</div>
					<div class="form-group col-6">
						<label for="postcode_usa">Code postal</label>
						<input type="text" class="form-control" id="postcode_usa" v-model="person.contacts.contact_usa.postcode" @blur="updateLocalPerson()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-6">
						<label for="tel_ci">Téléphone CI *</label>
						<input type="phone" class="form-control" id="tel_ci" v-model="person.contacts.contact_ci.phone" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="adresse_ci">Adresse CI *</label>
						<input type="text" class="form-control" id="adresse_ci" v-model="person.contacts.contact_ci.address" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="city_ci">Ville</label>
						<input type="text" class="form-control" id="city_ci" v-model="person.contacts.contact_ci.city" @blur="updateLocalPerson()">
					</div>
					<div class="form-group col-6">
						<label for="email">Adresse email</label>
						<input type="email" class="form-control" id="email" v-model="person.contacts.email" @blur="updateLocalPerson()">
					</div>
				</div>
			</fieldset>

			<div class="form-group">
				<a href="" class="btn btn-primary" @click.prevent="saveData()">
					<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
					Enregistrer
				</a>
			</div>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
import States from "../immatriculation/usa_states.json"
export default {
	name: 'NewTransient',
	components: { },
	data () {
		return {
			uid: null,
			etats: States,
			person: (localStorage.getItem('person') != 'undefined') ? JSON.parse(localStorage.getItem('person')) : {
				'lastname': null,
				'firstname': null,
				'profession': null,
				'arrival_date': null,
				'departure_date': null,
				'comment': null,
				'contacts': {
					'email': null,
					'contact_usa':{
						'phone': null,
						'address': null,
						'city': null,
						'state': "",
						'postcode': null,
					},
					'contact_ci':{
						'phone': null,
						'address': null,
						'city': null
					}
				}
			}
		}
	},
	methods: {
        resetForm(){
            this.person = {
				'lastname': null,
				'firstname': null,
				'profession': null,
				'arrival_date': null,
				'departure_date': null,
				'comment': null,
				'contacts': {
					'email': null,
					'contact_usa':{
						'phone': null,
						'address': null,
						'city': null,
						'state': "",
						'postcode': null,
					},
					'contact_ci':{
						'phone': null,
						'address': null,
						'city': null
					}
				}
			}
            localStorage.setItem('person', this.person)
        },
        updateLocalPerson() {

            if(this.person == null){
                this.person = {
					'lastname': null,
					'firstname': null,
					'profession': null,
					'arrival_date': null,
					'departure_date': null,
					'comment': null,
					'contacts': {
						'email': null,
						'contact_usa':{
							'phone': null,
							'address': null,
							'city': null,
							'state': "",
							'postcode': null,
						},
						'contact_ci':{
							'phone': null,
							'address': null,
							'city': null
						}
					}
				}
            }

          localStorage.setItem('person', JSON.stringify(this.person))
        },
		saveData () {
			if (this.person.lastname == null) {
				this.$swal('Nom', "Vous devez saisir le nom.", 'warning')
				return !1

			} else if (this.person.firstname == null) {
				this.$swal('Prénom', "Vous devez saisir le prénom.", 'warning')
				return !1

			} else if (this.person.profession == null) {
				this.$swal('Profession', "Vous devez saisir la profession.", 'warning')
				return !1

			} else if (this.person.arrival_date == null) {
				this.$swal("Date d'arrivée", "Vous devez saisir la date d'arrivée.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement de la personne en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				let requestMethod = (this.uid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.uid == null) ? 'transient' : 'transient/'+this.uid

				axios({
					method: requestMethod,
					headers: this.$root.authHeader(),
					url: requestUrl,
					data: {
						'lastname' : this.person.lastname,
						'firstname' : this.person.firstname,
						'contacts' : this.person.contacts,
						'profession' : this.person.profession,
						'arrival_date' : this.person.arrival_date,
						'departure_date' : this.person.departure_date,
						'comment' : this.person.comment
					},
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						let w = (this.uid == null) ? 'enregistrée' : 'modifiée'
						this.$swal("C'est fait", 'La personne de passage a bien été '+w+'.', 'success')

						if(this.uid == null){
							this.person = {
								'lastname': null,
								'firstname': null,
								'profession': null,
								'arrival_date': null,
								'departure_date': null,
								'comment': null,
								'contacts': {
									'email': null,
									'contact_usa':{
										'phone': null,
										'address': null,
										'city': null,
										'state': "",
										'postcode': null,
									},
									'contact_ci':{
										'phone': null,
										'address': null,
										'city': null
									}
								}
							}
						}

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				axios.interceptors.request.eject(this.interceptor)
			}
		},
		getPerson () {
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des informations...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'transient/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let u = r.data.transient

					this.person.lastname = u.lastname
					this.person.firstname = u.firstname
					this.person.profession = u.profession
					this.person.comment = u.comment
					this.person.arrival_date = u.arrival_date
					this.person.departure_date = u.departure_date
					this.person.contacts = (u.contacts != null) ? u.contacts : {
									'email': null,
									'contact_usa':{
										'phone': null,
										'address': null,
										'city': null,
										'state': "",
										'postcode': null,
									},
									'contact_ci':{
										'phone': null,
										'address': null,
										'city': null
									}
								}


					this.$swal.close()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function () {
				
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}

		this.updateLocalPerson()

	}
}
</script>
