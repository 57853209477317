import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NewImmat from './views/immatriculation/New.vue'
import ImmatList from './views/immatriculation/List.vue'
import ImmatDetails from './views/immatriculation/Details.vue'
import RoleIndex from './views/roles/Index.vue'
import CreateRole from './views/roles/CreateRole.vue'
import RoleDetails from './views/roles/Details.vue'
import Statistiques from './views/immatriculation/Statistiques.vue'
import RDV from './views/immatriculation/RDV.vue'
import PrintCards from './views/immatriculation/Print.vue'
import Documents from './views/immatriculation/Documents.vue'
import ImmatDocuments from './views/immatriculation/ImmatDocuments.vue'
import NewAsso from './views/associations/New.vue'
import AssosList from './views/associations/List.vue'
import EditAsso from './views/associations/New.vue'
import AssoDetails from './views/associations/Details.vue'
import NewDepot from './views/depots/New.vue'
import DepotsList from './views/depots/List.vue'
import EditDepot from './views/depots/New.vue'
import DepotDetails from './views/depots/Details.vue'
import NewTransient from './views/passage/New.vue'
import PersonsList from './views/passage/List.vue'
import EditTransient from './views/passage/New.vue'
import TransientDetails from './views/passage/Details.vue'
import Login from './views/Login.vue'
import CreateAccount from './views/accounts/CreateAccount.vue'
import AccountIndex from './views/accounts/Index.vue'
import AccountDetails from './views/accounts/Details.vue'
import Account from './views/accounts/MyAccount.vue'
import NewAct from './views/acts/New.vue'
import ActsList from './views/acts/List.vue'
import EditAct from './views/acts/New.vue'
import ActDetails from './views/acts/Details.vue'
import ActsStats from './views/acts/Stats.vue'
import Messagerie from './views/messagerie/Index.vue'
import MessagerieSMS from './views/messagerie/SMS.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      meta: {
        title: 'Backoffice - Application',
        requiresAuth: true
      },
      name: 'Home',
      component: Home
    },
    {
      path: '/immatriculation/new',
      meta: {
        title: 'Nouvelle immatriculation',
        requiresAuth: true
      },
      name: 'NewImmat',
      component: NewImmat
    },
    {
      path: '/immatriculation/print',
      meta: {
        title: 'Imprimer des cartes',
        requiresAuth: true
      },
      name: 'PrintCards',
      component: PrintCards
    },
    {
      path: '/immatriculation/documents',
      meta: {
        title: 'Gestion des documents',
        requiresAuth: true
      },
      name: 'Documents',
      component: Documents
    },
    {
      path: '/immatriculation/rdv',
      meta: {
        title: 'Rendez-vous',
        requiresAuth: true
      },
      name: 'RDV',
      component: RDV
    },
    {
      path: '/immatriculation/edit/:id',
      meta: {
        title: 'Modifier une immatriculation',
        requiresAuth: true
      },
      name: 'EditImmat',
      component: NewImmat
    },
    {
      path: '/immatriculation/documents/:id',
      meta: {
        title: 'Gestion de documents',
        requiresAuth: true
      },
      name: 'ImmatDocuments',
      component: ImmatDocuments
    },
    {
      path: '/immatriculation/:id',
      meta: {
        title: 'Consulter une immatriculation',
        requiresAuth: true
      },
      name: 'ImmatDetails',
      component: ImmatDetails
    },
    {
      path: '/immatriculations',
      meta: {
        title: 'Toutes les immatriculations',
        requiresAuth: true
      },
      name: 'ImmatList',
      component: ImmatList
    },
    {
      path: '/statistics',
      meta: {
        title: 'Statistiques',
        requiresAuth: true
      },
      name: 'Statistiques',
      component: Statistiques
    },
    {
      path: '/login',
      meta: {
        title: 'Authentification'
      },
      name: 'Login',
      component: Login
    },
    {
      path: '/roles/new',
      meta: {
        title: 'Créer un rôle - Backoffice',
        requiresAuth: true
      },
      name: 'CreateRole',
      component: CreateRole
    },
    {
      path: '/roles/:id',
      meta: {
        title: 'Afficher un rôle - Backoffice',
        requiresAuth: true
      },
      name: 'RoleDetails',
      component: RoleDetails
    },
    {
      path: '/roles/edit/:id',
      meta: {
        title: 'Modifier un rôle - Backoffice',
        requiresAuth: true
      },
      name: 'EditRole',
      component: CreateRole
    },
    {
      path: '/roles',
      meta: {
        title: 'Tous les rôles - Backoffice',
        requiresAuth: true
      },
      name: 'RoleIndex',
      component: RoleIndex
    },
    {
      path: '/associations',
      meta: {
        title: 'Toutes les associations',
        requiresAuth: true
      },
      name: 'AssosList',
      component: AssosList
    },
    {
      path: '/associations/new',
      meta: {
        title: 'Nouvelle association',
        requiresAuth: true
      },
      name: 'NewAsso',
      component: NewAsso
    },
    {
      path: '/associations/edit/:id',
      meta: {
        title: 'Mettre à jour une association',
        requiresAuth: true
      },
      name: 'EditAsso',
      component: EditAsso
    },
    {
      path: '/association/:id',
      meta: {
        title: 'Association',
        requiresAuth: true
      },
      name: 'AssoDetails',
      component: AssoDetails
    },
    {
      path: '/depots',
      meta: {
        title: 'Tous les dépôts',
        requiresAuth: true
      },
      name: 'DepotsList',
      component: DepotsList
    },
    {
      path: '/depots/new',
      meta: {
        title: 'Nouveau dépôt',
        requiresAuth: true
      },
      name: 'NewDepot',
      component: NewDepot
    },
    {
      path: '/depot/edit/:id',
      meta: {
        title: 'Mettre à jour un dépôt',
        requiresAuth: true
      },
      name: 'EditDepot',
      component: EditDepot
    },
    {
      path: '/depot/:id',
      meta: {
        title: 'Dépôt',
        requiresAuth: true
      },
      name: 'DepotDetails',
      component: DepotDetails
    },
    {
      path: '/passages',
      meta: {
        title: 'Tous les personnes de passages',
        requiresAuth: true
      },
      name: 'PersonsList',
      component: PersonsList
    },
    {
      path: '/passage/new',
      meta: {
        title: 'Enregistrer une personne de passage',
        requiresAuth: true
      },
      name: 'NewTransient',
      component: NewTransient
    },
    {
      path: '/passage/edit/:id',
      meta: {
        title: 'Mettre à jour un enregistrement',
        requiresAuth: true
      },
      name: 'EditTransient',
      component: EditTransient
    },
    {
      path: '/passage/:id',
      meta: {
        title: 'Personne de passage',
        requiresAuth: true
      },
      name: 'TransientDetails',
      component: TransientDetails
    },
    {
      path: '/acts',
      meta: {
        title: 'Toutes les demandes',
        requiresAuth: true
      },
      name: 'ActsList',
      component: ActsList
    },
    {
      path: '/act/new',
      meta: {
        title: 'Enregistrer une demande',
        requiresAuth: true
      },
      name: 'NewAct',
      component: NewAct
    },
    {
      path: '/act/edit/:id',
      meta: {
        title: 'Mettre à jour une demande',
        requiresAuth: true
      },
      name: 'EditAct',
      component: EditAct
    },
    {
      path: '/acts/stats',
      meta: {
        title: 'Statistiques des demandes d\'actes',
        requiresAuth: true
      },
      name: 'ActsStats',
      component: ActsStats
    },
    {
      path: '/act/:id',
      meta: {
        title: 'Acte demandé',
        requiresAuth: true
      },
      name: 'ActDetails',
      component: ActDetails
    },
    {
      path: '/accounts/new',
      meta: {
        title: 'Créer un compte - Backoffice',
        requiresAuth: true
      },
      name: 'CreateAccount',
      component: CreateAccount
    },
    {
      path: '/accounts/edit/:id',
      meta: {
        title: 'Modifier un compte - Backoffice',
        requiresAuth: true
      },
      name: 'EditAccount',
      component: CreateAccount
    },
    {
      path: '/accounts/:id',
      meta: {
        title: 'Consulter un compte - Backoffice',
        requiresAuth: true
      },
      name: 'AccountDetails',
      component: AccountDetails
    },
    {
      path: '/accounts',
      meta: {
        title: 'Tous les comptes - Backoffice',
        requiresAuth: true
      },
      name: 'AccountIndex',
      component: AccountIndex
    },
    {
      path: '/account',
      meta: {
        title: 'Mon compte - Backoffice',
        requiresAuth: true
      },
      name: 'Account',
      component: Account
    },
    {
      path: '/messagerie/mails',
      meta: {
        title: 'Messagerie - Backoffice',
        requiresAuth: true
      },
      name: 'Messagerie',
      component: Messagerie
    },
    {
      path: '/messagerie/sms',
      meta: {
        title: 'SMS - Backoffice',
        requiresAuth: true
      },
      name: 'MessagerieSMS',
      component: MessagerieSMS
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
