<template>
	<section class="main-container">
		<h1 v-if="uid">
			<router-link to="/Immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Retour
			</router-link>
			&nbsp;
			<i class="fa fa-edit"></i>
			Modifier une inscription
			<br>
			<small>{{individu.prenoms}} {{individu.nom_de_famille}}</small>
		</h1>
		<h1 v-else>
			<router-link to="/immatriculations" class="btn btn-outline-secondary btn-outline-info">
				<i class="fa fa-angle-left"></i>
				Immatriculations
			</router-link>
			&nbsp;
			<i class="fa fa-address-card-o"></i>
			Nouvelle inscription
		</h1>
		<a href="" class="reset_form" @click.prevent="resetForm()">Réinitialiser le formulaire</a>

		<div class="alert alert-info">
			Les champs marqués par une étoile (*) sont obligatoires.
		</div>

		<form method="post" enctype="multipart/form-data">
			<div class="aic_popup hide">
				<div class="center">
					<div id="for_photo" v-if="popupFor == 'photo'">
						<h4>Ajouter la photo d'identité</h4>
						
						<input class="selectFile" type="file" ref="photo" @change="loadImage($event, 'photo')" accept="image/*" capture="camera">
						<button id="btnPhoto" class="button btn btn-outline-primary" @click.prevent="$refs.photo.click()">
							Sélectionner une photo
						</button>
						<br><br>
						<cropper
							ref="photoCropper"
							class="upload-example-cropper"
							:src="photo.src"
							:stencil-props="{
								aspectRatio: 0.78,
							}"
						/>
						<br>
						<div class="button-wrapper">
							<button class="button btn btn-primary" @click.prevent="instanciateFile('photo')">
								Valider
							</button>
						</div>
					</div>
					<div id="for_signature" v-else>
						<h4>Ajouter la signature</h4>

						<input class="selectFile" type="file" ref="signature" @change="loadImage($event, 'signature')" accept="image/*">
						<button id="btnSignature" class="button btn btn-outline-primary" @click.prevent="$refs.signature.click()">
							Sélectionner une signature
						</button>
						<br><br>
						<cropper
							ref="signatureCropper"
							class="upload-example-cropper wide"
							:src="signature.src"
							:stencil-props="{
								
							}"
						/>
						<br>
						<div class="button-wrapper">
							<button class="button btn btn-primary" @click.prevent="instanciateFile('signature')">
								Valider
							</button>
						</div>
					</div>
					<button class="btn btn-outline-secondary topup" @click.prevent="closePopup()">Annuler</button>
				</div>
			</div>
			<fieldset>
				<legend>Immatriculation antérieure</legend>
				<div class="row">
					<div class="form-group col-4">
						<label for="ia_oui">
							OUI
							<input type="radio" class="" id="ia_oui" name="ia" v-model="individu.ia" value="1" @change="updateLocalIndividu()">
						</label>
						&nbsp;&nbsp;
						<label for="ia_non">
							NON
							<input type="radio" class="" id="ia_non" name="ia" v-model="individu.ia" value="0" @change="updateLocalIndividu()">
						</label>
						<br>
						<label for="cgci">Si oui, Consulat Général de Côte d'Ivoire à</label>
						<input type="text" class="form-control" id="cgci" placeholder="" v-model="individu.cgci" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-8">
						<div class="row">
							<div class="form-group col-6 center">
								<label for="photo" @click="openPopup('photo')" class="btn btn-label">
									Ajouter une photo *
								</label>
								<br><br>
								<!--input type="file" name="photo" id="photo" class="" @change="choosePhoto()"-->
								<div class="preview_picture">
									<img :src="photoPreview" alt="" class="photoPreview">
								</div>
							</div>
							<div class="form-group col-6 center">
								<label for="signature" @click="openPopup('signature')" class="btn btn-label">
									Ajouter une signature *
								</label>
								<br><br>
								<!--input type="file" name="signature" id="signature" class=""-->
								<div class="preview_signature">
									<img :src="signaturePreview" alt="" class="photoPreview">
								</div>
							</div>
						</div>
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Bloc civil</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="nom_de_famille">Genre *</label>
						<select class="form-control" id="genre" v-model="individu.genre" @change="updateLocalIndividu()">
							<option value="m">Homme</option>
							<option value="f">Femme</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="nom_de_famille">Nom de famille *</label>
						<input type="text" class="form-control upper" id="nom_de_famille" placeholder="" v-model="individu.nom_de_famille" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="nom_usage">Nom d'usage</label>
						<input type="text" class="form-control upper" id="nom_usage" placeholder="" v-model="individu.nom_usage" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="prenoms">Prénoms *</label>
						<input type="text" class="form-control capitalize" id="prenoms" placeholder="" v-model="individu.prenoms" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="date_naissance">Date de naissance *</label>
						<input type="date" class="form-control" id="date_naissance" placeholder="" v-model="individu.date_naissance" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="lieu_naissance">Lieu de naissance *</label>
						<input type="text" class="form-control capitalize" id="lieu_naissance" placeholder="" v-model="individu.lieu_naissance" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="commune_sp_naissance">Commune / S/P</label>
						<input type="text" class="form-control capitalize" id="commune_sp_naissance" placeholder="" v-model="individu.commune_sp_naissance" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="pays_naissance">Pays *</label>
						<select class="form-control" id="pays_naissance" v-model="individu.pays_naissance" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option :value="k" v-for="(p, k) in $root.pays" :key="k">{{p}}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="couleur_yeux">Couleur des yeux</label>
						<select class="form-control" id="couleur_yeux" v-model="individu.couleur_yeux" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option :value="c" v-for="c in eyesColors" :key="c">{{c}}</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="taille">Taille en cm</label>
						<input type="number" class="form-control" id="taille" v-model="individu.taille" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Nationalité</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="passeport_numero">Passeport N°</label>
						<input type="text" class="form-control" id="passeport_numero" placeholder="" v-model="individu.passeport.numero" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="passeport_date_delivrance">Date de délivrance</label>
						<input type="date" class="form-control" id="passeport_date_delivrance" placeholder="" v-model="individu.passeport.date_delivrance" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="passeport_date_expiration">Date d'expiration</label>
						<input type="date" class="form-control" id="passeport_date_expiration" v-model="individu.passeport.date_expiration" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="passeport_lieu_delivrance">Lieu de délivrance</label>
						<input type="text" class="form-control capitalize" id="passeport_lieu_delivrance" v-model="individu.passeport.lieu_delivrance" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="carte_identite_numero">Carte d'identité N°</label>
						<input type="text" class="form-control" id="carte_identite_numero" placeholder="" v-model="individu.carte_identite.numero" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="carte_identite_date_delivrance">Date de délivrance</label>
						<input type="date" class="form-control" id="carte_identite_date_delivrance" placeholder="" v-model="individu.carte_identite.date_delivrance" @change="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="carte_identite_date_expiration">Date d'expiration</label>
						<input type="date" class="form-control" id="carte_identite_date_expiration" v-model="individu.carte_identite.date_expiration" @change="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="carte_identite_lieu_delivrance">Lieu de délivrance</label>
						<input type="text" class="form-control capitalize" id="carte_identite_lieu_delivrance" v-model="individu.carte_identite.lieu_delivrance" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="certificat_nationalite_ivoirienne_numero">Certificat Nationalité Ivoirienne N°</label>
						<input type="text" class="form-control" id="certificat_nationalite_ivoirienne_numero" placeholder="" v-model="individu.certificat_nationalite_ivoirienne.numero" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="certificat_nationalite_ivoirienne_date_delivrance">Date de délivrance</label>
						<input type="date" class="form-control" id="certificat_nationalite_ivoirienne_date_delivrance" placeholder="" v-model="individu.certificat_nationalite_ivoirienne.date_delivrance" @change="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="certificat_nationalite_ivoirienne_lieu_delivrance">Lieu de délivrance</label>
						<input type="text" class="form-control capitalize" id="certificat_nationalite_ivoirienne_lieu_delivrance" v-model="individu.certificat_nationalite_ivoirienne.lieu_delivrance" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Père</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="pere_nom">Nom</label>
						<input type="text" class="form-control upper" id="pere_nom" placeholder="" v-model="individu.pere.nom" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="pere_prenoms">Prénoms</label>
						<input type="text" class="form-control capitalize" id="pere_prenoms" placeholder="" v-model="individu.pere.prenoms" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="pere_date_naissance">Date de naissance</label>
						<input type="date" class="form-control" id="pere_date_naissance" placeholder="" v-model="individu.pere.date_naissance" @change="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="pere_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="pere_ville" placeholder="" v-model="individu.pere.ville" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="pere_departement">Département</label>
						<input type="text" class="form-control capitalize" id="pere_departement" placeholder="" v-model="individu.pere.departement" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="pere_pays">Pays</label>
						<select class="form-control" id="pere_pays" v-model="individu.pere.pays" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option :value="k" v-for="(p, k) in $root.pays" :key="k">{{p}}</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="pere_nationalite">Nationalité</label>
						<input type="text" class="form-control capitalize" id="pere_nationalite" placeholder="" v-model="individu.pere.nationalite" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Mère</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="mere_nom">Nom</label>
						<input type="text" class="form-control upper" id="mere_nom" placeholder="" v-model="individu.mere.nom" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mere_prenoms">Prénoms</label>
						<input type="text" class="form-control capitalize" id="mere_prenoms" placeholder="" v-model="individu.mere.prenoms" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mere_date_naissance">Date de naissance</label>
						<input type="date" class="form-control" id="mere_date_naissance" placeholder="" v-model="individu.mere.date_naissance" @change="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="mere_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="mere_ville" placeholder="" v-model="individu.mere.ville" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mere_departement">Département</label>
						<input type="text" class="form-control capitalize" id="mere_departement" placeholder="" v-model="individu.mere.departement" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="mere_pays">Pays</label>
						<select class="form-control" id="mere_pays" v-model="individu.mere.pays" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option :value="k" v-for="(p, k) in $root.pays" :key="k">{{p}}</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="mere_nationalite">Nationalité</label>
						<input type="text" class="form-control capitalize" id="mere_nationalite" placeholder="" v-model="individu.mere.nationalite" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Situation de famille</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="lien_familial_situation_matrimoniale">Situation matrimoniale</label>
						<select class="form-control" id="lien_familial_situation_matrimoniale" v-model="individu.lien_familial.situation_matrimoniale" @change="updateLocalIndividu()">
							<option value="">Sélectionnez</option>
							<option value="Marié(e)">Marié(e)</option>
							<option value="Divorcé(e)">Divorcé(e)</option>
							<option value="Célibataire">Célibataire</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="lien_familial_date">Date</label>
						<input type="date" class="form-control" id="lien_familial_date" v-model="individu.lien_familial.date" @change="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="lien_familial_lieu">Lieu</label>
						<input type="text" class="form-control capitalize" id="lien_familial_lieu" v-model="individu.lien_familial.lieu" @blur="updateLocalIndividu()">
					</div>
				</div>
				<h4>
					Membres de la famille qui résident avec l'individu
					&nbsp;&nbsp;&nbsp;
					<button class="btn btn-secondary" @click.prevent="addFamilyMember()">
						<i class="fa fa-plus"></i>&nbsp;
						Ajouter
					</button>
				</h4>
				<div id="family_members">
					<div class="row" :id="'fm_'+k" v-for="(v, k) in individu.membres_de_famille_residents" :key="k">
						<div class="form-group col-3">
							<label for="'fm_'+k+'_nom'">Nom</label>
							<input type="text" class="form-control upper" id="'fm_'+k+'_nom'" placeholder="" v-model="individu.membres_de_famille_residents[k].nom" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="'fm_'+k+'_prenoms'">Prénoms</label>
							<input type="text" class="form-control capitalize" id="'fm_'+k+'_prenoms'" placeholder="" v-model="individu.membres_de_famille_residents[k].prenoms" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="'fm_'+k+'_date_naissance'">Date de naissance</label>
							<input type="date" class="form-control" id="'fm_'+k+'_date_naissance'" placeholder="" v-model="individu.membres_de_famille_residents[k].date_naissance" @change="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="'fm_'+k+'_date_naissance'">Lieu de naissance</label>
							<input type="text" class="form-control capitalize" id="'fm_'+k+'_date_naissance'" placeholder="" v-model="individu.membres_de_famille_residents[k].lieu_naissance" @blur="updateLocalIndividu()">
						</div>
					</div>
					<!--div class="row" id="fm_0">
						<div class="form-group col-3">
							<label for="fm_0_nom">Nom</label>
							<input type="text" class="form-control upper" id="fm_0_nom" placeholder="" v-model="individu.membres_de_famille_residents[0].nom" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="fm_0_prenoms">Prénoms</label>
							<input type="text" class="form-control capitalize" id="fm_0_prenoms" placeholder="" v-model="individu.membres_de_famille_residents[0].prenoms" @blur="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="fm_0_date_naissance">Date de naissance</label>
							<input type="date" class="form-control" id="fm_0_date_naissance" placeholder="" v-model="individu.membres_de_famille_residents[0].date_naissance" @change="updateLocalIndividu()">
						</div>
						<div class="form-group col-3">
							<label for="fm_0_date_naissance">Lieu de naissance</label>
							<input type="text" class="form-control capitalize" id="fm_0_date_naissance" placeholder="" v-model="individu.membres_de_famille_residents[0].lieu_naissance" @blur="updateLocalIndividu()">
						</div>
					</div-->
				</div>
			</fieldset>

			<fieldset>
				<legend>Contacts aux États-Unis</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="adresse_adresse">Adresse</label>
						<input type="text" class="form-control" id="adresse_adresse" placeholder="" v-model="individu.adresse.adresse" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="adresse_etat">État</label>
						<select id="adresse_etat" class="form-control" v-model="individu.adresse.etat" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option 
								v-for="etat in etats"
								:key="etat"
								:value="etat">{{etat}}</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="adresse_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="adresse_ville" placeholder="" v-model="individu.adresse.ville" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="adresse_code_postal">Code postal</label>
						<input type="text" class="form-control" id="adresse_code_postal" placeholder="" v-model="individu.adresse.code_postal" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="telephone_fixe">Téléphone fixe</label>
						<input type="phone" class="form-control" id="telephone_fixe" v-model="individu.telephone_fixe" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="telephone_portable">Téléphone portable</label>
						<input type="phone" class="form-control" id="telephone_portable" v-model="individu.telephone_portable" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="email">Email</label>
						<input type="email" class="form-control" id="email" v-model="individu.email" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-12">
						Acceptez-vous que vos coordonnées téléphoniques ou de messagerie soient utilisées pour l'envoi de courriel/SMS ?
						&nbsp;&nbsp;
						<label for="consentement_de_contact_oui">
							OUI
							<input type="radio" class="" id="consentement_de_contact_oui" name="consentement_de_contact" v-model="individu.consentement_de_contact" value="1" @change="updateLocalIndividu()">
						</label>
						&nbsp;&nbsp;
						<label for="consentement_de_contact_non">
							NON
							<input type="radio" class="" id="consentement_de_contact_non" name="consentement_de_contact" v-model="individu.consentement_de_contact" value="0" @change="updateLocalIndividu()">
						</label>
						
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Situation socio-professionnelle aux États-Unis</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="profession">Profession</label>
						<input type="text" class="form-control" id="profession" v-model="individu.profession" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="employeur">Employeur</label>
						<input type="text" class="form-control" id="employeur" v-model="individu.employeur" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="telephone_professionnel">Téléphone professionnel</label>
						<input type="phone" class="form-control" id="telephone_professionnel" v-model="individu.telephone_professionnel" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="email_professionnelle">Email</label>
						<input type="email" class="form-control" id="email_professionnelle" v-model="individu.email_professionnelle" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="adresse_professionnelle_adresse">Adresse</label>
						<input type="text" class="form-control" id="adresse_professionnelle_adresse" placeholder="" v-model="individu.adresse_professionnelle.adresse" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="adresse_professionnelle_etat">État</label>
						<select id="adresse_professionnelle_etat" class="form-control" v-model="individu.adresse_professionnelle.etat" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option 
								v-for="etat in etats"
								:key="etat"
								:value="etat">{{etat}}</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="adresse_professionnelle_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="adresse_professionnelle_ville" placeholder="" v-model="individu.adresse_professionnelle.ville" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="adresse_professionnelle_code_postal">Code postal</label>
						<input type="text" class="form-control" id="adresse_professionnelle_code_postal" placeholder="" v-model="individu.adresse_professionnelle.code_postal" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Situation électorale (Élections présidentielles, référendums, diaspora)</legend>
				<div class="row">
					<div class="form-group col-12">
						Je demande mon inscription sur la liste électorale consulaire de New-York
						&nbsp;&nbsp;
						<label for="inscription_sur_liste_electorale_oui">
							OUI
							<input type="radio" class="" id="inscription_sur_liste_electorale_oui" name="inscription_sur_liste_electorale" v-model="individu.inscription_sur_liste_electorale" value="1" @change="updateLocalIndividu()">
						</label>
						&nbsp;&nbsp;
						<label for="inscription_sur_liste_electorale_non">
							NON
							<input type="radio" class="" id="inscription_sur_liste_electorale_non" name="inscription_sur_liste_electorale" v-model="individu.inscription_sur_liste_electorale" value="0" @change="updateLocalIndividu()">
						</label>
						
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						Inscrit(e) en Côte d'Ivoire sur la liste électorale de
					</div>
					<div class="form-group col-6">
						<label for="inscription_en_ci_commune">Commune</label>
						<input type="text" class="form-control capitalize" id="inscription_en_ci_commune" v-model="individu.inscription_en_ci.commune" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-6">
						<label for="inscription_en_ci_departement">Département</label>
						<input type="text" class="form-control capitalize" id="inscription_en_ci_departement" v-model="individu.inscription_en_ci.departement" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						Si vous êtes inscrit(e) en Côte d'Ivoire, souhaitez-vous voter dans votre commune en Côte d'Ivoire ou à New-York ?
					</div>
					<div class="form-group col-6">
						<label for="lieu_de_vote_souhaite">Indiquez le lieu</label>
						<input type="text" class="form-control capitalize" id="lieu_de_vote_souhaite" v-model="individu.lieu_de_vote_souhaite" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Personne à prévenir en cas d'urgence aux États-Unis</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_nom">Nom</label>
						<input type="text" class="form-control upper" id="personnes_a_prevenir_usa_nom" v-model="individu.personnes_a_prevenir_usa.nom" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_prenoms">Prénoms</label>
						<input type="text" class="form-control capitalize" id="personnes_a_prevenir_usa_prenoms" v-model="individu.personnes_a_prevenir_usa.prenoms" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_lien_de_parente">Lien de parenté</label>
						<input type="text" class="form-control" id="personnes_a_prevenir_usa_lien_de_parente" v-model="individu.personnes_a_prevenir_usa.lien_de_parente" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_adresse_adresse">Adresse</label>
						<input type="text" class="form-control" id="personnes_a_prevenir_usa_adresse_adresse" v-model="individu.personnes_a_prevenir_usa.adresse.adresse" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_adresse_etat">État</label>
						<select id="personnes_a_prevenir_usa_adresse_etat" class="form-control" v-model="individu.personnes_a_prevenir_usa.adresse.etat" @change="updateLocalIndividu()">
							<option value="">Choisissez</option>
							<option 
								v-for="etat in etats"
								:key="etat"
								:value="etat">{{etat}}</option>
						</select>
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_adresse_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="personnes_a_prevenir_usa_adresse_ville" v-model="individu.personnes_a_prevenir_usa.adresse.ville" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_adresse_code_postal">Code postal</label>
						<input type="text" class="form-control" id="personnes_a_prevenir_usa_adresse_code_postal" v-model="individu.personnes_a_prevenir_usa.adresse.code_postal" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_telephone">Téléphone</label>
						<input type="phone" class="form-control" id="personnes_a_prevenir_usa_telephone" v-model="individu.personnes_a_prevenir_usa.telephone" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_usa_email">Email</label>
						<input type="email" class="form-control" id="personnes_a_prevenir_usa_email" v-model="individu.personnes_a_prevenir_usa.email" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>

			<fieldset>
				<legend>Personne à prévenir en cas d'urgence en Côte d'Ivoire</legend>
				<div class="row">
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_nom">Nom</label>
						<input type="text" class="form-control upper" id="personnes_a_prevenir_ci_nom" v-model="individu.personnes_a_prevenir_ci.nom" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_prenoms">Prénoms</label>
						<input type="text" class="form-control capitalize" id="personnes_a_prevenir_ci_prenoms" v-model="individu.personnes_a_prevenir_ci.prenoms" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_lien_de_parente">Lien de parenté</label>
						<input type="text" class="form-control" id="personnes_a_prevenir_ci_lien_de_parente" v-model="individu.personnes_a_prevenir_ci.lien_de_parente" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_adresse_adresse">Adresse</label>
						<input type="text" class="form-control" id="personnes_a_prevenir_ci_adresse_adresse" v-model="individu.personnes_a_prevenir_ci.adresse.adresse" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_adresse_ville">Ville</label>
						<input type="text" class="form-control capitalize" id="personnes_a_prevenir_ci_adresse_ville" v-model="individu.personnes_a_prevenir_ci.adresse.ville" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_adresse_code_postal">Code postal</label>
						<input type="text" class="form-control" id="personnes_a_prevenir_ci_adresse_code_postal" v-model="individu.personnes_a_prevenir_ci.adresse.code_postal" @blur="updateLocalIndividu()">
					</div>
				</div>
				<div class="row">
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_telephone">Téléphone</label>
						<input type="phone" class="form-control" id="personnes_a_prevenir_ci_telephone" v-model="individu.personnes_a_prevenir_ci.telephone" @blur="updateLocalIndividu()">
					</div>
					<div class="form-group col-3">
						<label for="personnes_a_prevenir_ci_email">Email</label>
						<input type="email" class="form-control" id="personnes_a_prevenir_ci_email" v-model="individu.personnes_a_prevenir_ci.email" @blur="updateLocalIndividu()">
					</div>
				</div>
			</fieldset>
			<br>
			<div class="form-group">
				<a href="" class="btn btn-primary" @click.prevent="saveData()">
					<i class="fa fa-floppy-o"></i> &nbsp;&nbsp;&nbsp;
					Enregistrer et passer aux documents
				</a>
			</div>
		</form>
	</section>
</template>

<script>
import axios from 'axios'
import States from "./usa_states.json"
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
	name: 'CreateAccount',
	components: { Cropper },
	data () {
		return {
			photo: {
				src: null,
				type: null
			},
			signature: {
				src: null,
				type: null
			},
			popupFor: 'photo',
			person: null,
			photoPreview: null,
			signaturePreview: null,
			photo_: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
			signature_: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
			uid: null,
			etats: States,
			eyesColors: ['Marron','Verts','Bleus','Noirs','Violets','Gris','Vairons','Fauves','Rouges','Roses'],
			individu: (localStorage.getItem('individu') != 'undefined') ? JSON.parse(localStorage.getItem('individu')) : {
				'ia': 0,
				'cgci': "",
				'genre': "m",
				'nom_de_famille': "",
				'nom_usage': "",
				'prenoms': "",
				'date_naissance': "",
				'lieu_naissance': "",
				'commune_sp_naissance': "",
				'pays_naissance': "",
				'couleur_yeux': "",
				'taille': "",
				'passeport': {
					'numero': "",
					'date_delivrance': "",
					'date_expiration': "",
					'lieu_delivrance': ""
				},
				'carte_identite': {
					'numero': "",
					'date_delivrance': "",
					'date_expiration': "",
					'lieu_delivrance': ""
				},
				'certificat_nationalite_ivoirienne': {
					'numero': "",
					'date_delivrance': "",
					'lieu_delivrance': ""
				},
				'pere': {
					'nom': "",
					'prenoms': "",
					'date_naissance': "",
					'ville': "",
					'departement': "",
					'pays': "",
					'nationalite': ""
				},
				'mere': {
					'nom': "",
					'prenoms': "",
					'date_naissance': "",
					'ville': "",
					'departement': "",
					'pays': "",
					'nationalite': ""
				},
				'lien_familial': {
					'situation_matrimoniale': "",
					'date': "",
					'lieu': ""
				},
				'membres_de_famille_residents': [
					{
						'nom': "",
						'prenoms': "",
						'date_naissance': "",
						'lieu_naissance': ""
					}
				],
				'adresse': {
					'adresse': "",
					'ville': "",
					'code_postal': "",
					'etat': ""
				},
				'consentement_de_contact': 0,
				'telephone_fixe': "",
				'telephone_portable': "",
				'email': "",
				'inscription_sur_liste_electorale': 0,
				'inscription_en_ci': {
					'commune': "",
					'departement': "",
				},
				'lieu_de_vote_souhaite': "",
				'profession': "",
				'employeur': "",
				'adresse_professionnelle': {
					'adresse': "",
					'ville': "",
					'code_postal': "",
					'etat': ""
				},
				'telephone_professionnel': "",
				'telecopie_professionnelle': "",
				'email_professionnelle': "",
				'personnes_a_prevenir_usa': {
					'nom': "",
					'prenoms': "",
					'lien_de_parente': "",
					'adresse': {
						'adresse': "",
						'ville': "",
						'code_postal': "",
						'etat': ""
					},
					'telephone': "",
					'telecopie': "",
					'email': ""
				},
				'personnes_a_prevenir_ci': {
					'nom': "",
					'prenoms': "",
					'lien_de_parente': "",
					'adresse': {
						'adresse': "",
						'ville': "",
						'code_postal': ""
					},
					'telephone': "",
					'telecopie': "",
					'email': ""
				}
			},
			countFamilyMembers: 1
		}
	},
	methods: {
		/*crop() {
			const { canvas } = this.$refs.cropper.getResult();
			canvas.toBlob((blob) => {
				// Do something with blob: upload to a server, download and etc.
			}, this.image.type);
		},*/
		closePopup(){
			document.querySelector('.aic_popup').classList.add("hide")
		},
		openPopup(t){
			document.querySelector('.aic_popup').classList.remove("hide")
			this.popupFor = t
			this.reset(t)
			/*switch(t){
				case('photo'):
					document.querySelector("#btnPhoto").trigger('click')
				break;
			}*/
		},
		addFamilyMember(){
			let nextMember = this.countFamilyMembers
			let memberLine = '<div class="row" id="fm_'+nextMember+'">' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_nom">Nom</label>' +
									'<input type="text" class="form-control upper" id="fm_'+nextMember+'_nom" placeholder="" v-model="individu.membres_de_famille_residents['+nextMember+'].nom" @blur="updateLocalIndividu()">' +
								'</div>' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_prenoms">Prénoms</label>' +
									'<input type="text" class="form-control capitalize" id="fm_'+nextMember+'_prenoms" placeholder="" v-model="individu.membres_de_famille_residents['+nextMember+'].prenoms" @blur="updateLocalIndividu()">' +
								'</div>' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_date_naissance">Date de naissance</label>' +
									'<input type="date" class="form-control" id="fm_'+nextMember+'_date_naissance" placeholder="" v-model="individu.membres_de_famille_residents['+nextMember+'].date_naissance" @change="updateLocalIndividu()">' +
								'</div>' +
								'<div class="form-group col-3">' +
									'<label for="fm_'+nextMember+'_date_naissance">Lieu de naissance</label>' +
									'<input type="text" class="form-control capitalize" id="fm_'+nextMember+'_date_naissance" placeholder="" v-model="individu.membres_de_famille_residents['+nextMember+'].lieu_naissance" @blur="updateLocalIndividu()">' +
								'</div>' +
							'</div>'

			document.querySelector("#family_members").innerHTML += memberLine
		},
		instanciateFile(t) {
			const { canvas } = (t == 'photo') ? this.$refs.photoCropper.getResult() : this.$refs.signatureCropper.getResult()
			
			if (canvas) {
				let vm = this
				canvas.toBlob(blob => {
					const reader = new FileReader()

					switch(t){
						case('photo'): vm.photo_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.photoPreview = reader.result
								
							};
						break;
						case('signature'): vm.signature_ = blob
							reader.readAsDataURL(blob)
							reader.onload = function() {
								vm.signaturePreview = reader.result
								
							};
						break;
					}

				}, 'image/jpeg');

				document.querySelector('.aic_popup').classList.add("hide")
			}
		},
		reset(t) {
			if(t == 'photo'){
				this.photo = {
					src: null,
					type: null
				}
			}
			else if(t == 'signature'){
				this.signature = {
					src: null,
					type: null
				}
			}
		},
		loadImage(event, t) {

			const { files } = event.target

			if (files && files[0]) {

				const blob = URL.createObjectURL(files[0])
				const reader = new FileReader()

				switch(t){
					case('photo'):
						if (this.photo.src) {
							URL.revokeObjectURL(this.photo.src)
						}

						reader.onload = (e) => {

							this.photo = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
					case('signature'):
						if (this.signature.src) {
							URL.revokeObjectURL(this.signature.src)
						}

						reader.onload = (e) => {

							this.signature = {

								src: blob,

								type: getMimeType(e.target.result, files[0].type),
							};
						};
					break;
				}

				reader.readAsArrayBuffer(files[0]);
			}
		},
		getImgData(files) {

			if (files) {
				const fileReader = new FileReader();
				fileReader.readAsDataURL(files);
				fileReader.addEventListener("load", function () {
					return this.result
					//this.photo_ = this.result
					//console.log(this.result)
					//imgPreview.style.display = "block";
					//imgPreview.innerHTML = '<img src="' + this.result + '" />';
				});    
			}
		},
		choosePhoto(){
			this.photo_ = document.querySelector('#photo').files[0]
			console.log(this.getImgData(this.photo_))
			//console.log(this.photo_)
		},
		chooseSignature(){
			this.signature_ = document.querySelector('#signature').files[0]
		},
        resetForm(){
            this.individu = {
				'ia': 0,
				'cgci': "",
				'genre': "m",
				'nom_de_famille': "",
				'nom_usage': "",
				'prenoms': "",
				'date_naissance': "",
				'lieu_naissance': "",
				'commune_sp_naissance': "",
				'pays_naissance': "",
				'couleur_yeux': "",
				'taille': "",
				'passeport': {
					'numero': "",
					'date_delivrance': "",
					'date_expiration': "",
					'lieu_delivrance': ""
				},
				'carte_identite': {
					'numero': "",
					'date_delivrance': "",
					'date_expiration': "",
					'lieu_delivrance': ""
				},
				'certificat_nationalite_ivoirienne': {
					'numero': "",
					'date_delivrance': "",
					'lieu_delivrance': ""
				},
				'pere': {
					'nom': "",
					'prenoms': "",
					'date_naissance': "",
					'ville': "",
					'departement': "",
					'pays': "",
					'nationalite': ""
				},
				'mere': {
					'nom': "",
					'prenoms': "",
					'date_naissance': "",
					'ville': "",
					'departement': "",
					'pays': "",
					'nationalite': ""
				},
				'lien_familial': {
					'situation_matrimoniale': "",
					'date': "",
					'lieu': ""
				},
				'membres_de_famille_residents': [
					{
						'nom': "",
						'prenoms': "",
						'date_naissance': "",
						'lieu_naissance': ""
					}
				],
				'adresse': {
					'adresse': "",
					'ville': "",
					'code_postal': "",
					'etat': ""
				},
				'consentement_de_contact': 0,
				'telephone_fixe': "",
				'telephone_portable': "",
				'email': "",
				'inscription_sur_liste_electorale': 0,
				'inscription_en_ci': {
					'commune': "",
					'departement': "",
				},
				'lieu_de_vote_souhaite': "",
				'profession': "",
				'employeur': "",
				'adresse_professionnelle': {
					'adresse': "",
					'ville': "",
					'code_postal': "",
					'etat': ""
				},
				'telephone_professionnel': "",
				'telecopie_professionnelle': "",
				'email_professionnelle': "",
				'personnes_a_prevenir_usa': {
					'nom': "",
					'prenoms': "",
					'lien_de_parente': "",
					'adresse': {
						'adresse': "",
						'ville': "",
						'code_postal': "",
						'etat': ""
					},
					'telephone': "",
					'telecopie': "",
					'email': ""
				},
				'personnes_a_prevenir_ci': {
					'nom': "",
					'prenoms': "",
					'lien_de_parente': "",
					'adresse': {
						'adresse': "",
						'ville': "",
						'code_postal': ""
					},
					'telephone': "",
					'telecopie': "",
					'email': ""
				}
			}
            localStorage.setItem('individu', this.individu)
        },
        updateLocalIndividu() {

            if(this.individu == null){
                this.individu = {
					'ia': 0,
					'cgci': "",
					'genre': "m",
					'nom_de_famille': "",
					'nom_usage': "",
					'prenoms': "",
					'date_naissance': "",
					'lieu_naissance': "",
					'commune_sp_naissance': "",
					'pays_naissance': "",
					'couleur_yeux': "",
					'taille': "",
					'passeport': {
						'numero': "",
						'date_delivrance': "",
						'date_expiration': "",
						'lieu_delivrance': ""
					},
					'carte_identite': {
						'numero': "",
						'date_delivrance': "",
						'date_expiration': "",
						'lieu_delivrance': ""
					},
					'certificat_nationalite_ivoirienne': {
						'numero': "",
						'date_delivrance': "",
						'lieu_delivrance': ""
					},
					'pere': {
						'nom': "",
						'prenoms': "",
						'date_naissance': "",
						'ville': "",
						'departement': "",
						'pays': "",
						'nationalite': ""
					},
					'mere': {
						'nom': "",
						'prenoms': "",
						'date_naissance': "",
						'ville': "",
						'departement': "",
						'pays': "",
						'nationalite': ""
					},
					'lien_familial': {
						'situation_matrimoniale': "",
						'date': "",
						'lieu': ""
					},
					'membres_de_famille_residents': [
						{
							'nom': "",
							'prenoms': "",
							'date_naissance': "",
							'lieu_naissance': ""
						}
					],
					'adresse': {
						'adresse': "",
						'ville': "",
						'code_postal': "",
						'etat': ""
					},
					'consentement_de_contact': 0,
					'telephone_fixe': "",
					'telephone_portable': "",
					'email': "",
					'inscription_sur_liste_electorale': 0,
					'inscription_en_ci': {
						'commune': "",
						'departement': "",
					},
					'lieu_de_vote_souhaite': "",
					'profession': "",
					'employeur': "",
					'adresse_professionnelle': {
						'adresse': "",
						'ville': "",
						'code_postal': "",
						'etat': ""
					},
					'telephone_professionnel': "",
					'telecopie_professionnelle': "",
					'email_professionnelle': "",
					'personnes_a_prevenir_usa': {
						'nom': "",
						'prenoms': "",
						'lien_de_parente': "",
						'adresse': {
							'adresse': "",
							'ville': "",
							'code_postal': "",
							'etat': ""
						},
						'telephone': "",
						'telecopie': "",
						'email': ""
					},
					'personnes_a_prevenir_ci': {
						'nom': "",
						'prenoms': "",
						'lien_de_parente': "",
						'adresse': {
							'adresse': "",
							'ville': "",
							'code_postal': ""
						},
						'telephone': "",
						'telecopie': "",
						'email': ""
					}
				}
            }

          localStorage.setItem('individu', JSON.stringify(this.individu))
        },
        getDocuments(){

        },
		saveData () {
			let vm = this
			//let photo = document.querySelector('#photo').files[0]
			//let signature = document.querySelector('#signature').files[0]
			//console.log(this.individu)

			if (this.individu.nom_de_famille == null) {
				this.$swal('Nom', "Vous devez saisir le nom de l'individu.", 'warning')
				return !1

			} else if (this.individu.prenoms == null) {
				this.$swal('Nom', "Vous devez saisir le prénom de l'individu.", 'warning')
				return !1

			} else {
				this.interceptor = axios.interceptors.request.use((config) => {
					this.$swal('Veuillez patienter', 'Enregistrement en cours...', 'info')
					return config
				}, (error) => {
					return Promise.reject(error)
				})

				//let requestMethod = (this.uid == null) ? 'POST' : 'PUT'
				let requestUrl = (this.uid == null) ? 'registration' : 'registration/update/'+this.uid

				let datas = new FormData()

				if(this.uid == null){
					datas.append('photo', this.photo_)
					datas.append('signature', this.signature_)
				}

				datas.append('gender', this.individu.genre)
				datas.append('lastname', this.individu.nom_de_famille)
				datas.append('firstname', this.individu.prenoms)
				datas.append('birthday', this.individu.date_naissance)
				datas.append('usename', this.individu.nom_usage)
				datas.append('birthplace', this.individu.lieu_naissance)
				datas.append('town_sp_birth', this.individu.commune_sp_naissance)
				datas.append('country_of_birth', this.individu.pays_naissance)
				datas.append('eye_colour', this.individu.couleur_yeux)
				datas.append('height', this.individu.taille)
				datas.append('previous_registration', JSON.stringify({
							"ca": parseInt(this.individu.ia),
							"cgci": this.individu.cgci
						}))
				datas.append('nationality', JSON.stringify({
							"passeport": {
								"number": this.individu.passeport.numero,
								"date_issue": this.individu.passeport.date_delivrance,
								"expiration_date": this.individu.passeport.date_expiration,
								"place_delivery": this.individu.passeport.lieu_delivrance
							},
							"identity_card": {
								"number": this.individu.carte_identite.numero,
								"date_issue": this.individu.carte_identite.date_delivrance,
								"expiration_date": this.individu.carte_identite.date_expiration,
								"place_delivery": this.individu.carte_identite.lieu_delivrance
							},
							"ivorian_nationality_certificate": {
								"number": this.individu.certificat_nationalite_ivoirienne.numero,
								"date_issue": this.individu.certificat_nationalite_ivoirienne.date_delivrance,
								"place_delivery": this.individu.certificat_nationalite_ivoirienne.lieu_delivrance
							},
							"other_nationalities": ""
						}))
				datas.append('parents', JSON.stringify({
							"father": {
								"lastname": this.individu.pere.nom,
								"firstname": this.individu.pere.prenoms,
								"birthday": this.individu.pere.date_naissance,
								"city": this.individu.pere.ville,
								"department": this.individu.pere.departement,
								"country": this.individu.pere.pays,
								"nationality": this.individu.pere.nationalite
							},
							"mother": {
								"lastname": this.individu.mere.nom,
								"firstname": this.individu.mere.prenoms,
								"birthday": this.individu.mere.date_naissance,
								"city": this.individu.mere.ville,
								"department": this.individu.mere.departement,
								"country": this.individu.mere.pays,
								"nationality": this.individu.mere.nationalite
							}
						}))
				datas.append('family_status', JSON.stringify({
							"family_link": {
								"marital_status": this.individu.lien_familial.situation_matrimoniale,
								"date": this.individu.lien_familial.date,
								"place": this.individu.lien_familial.lieu
							},
							"resident_family_members": this.individu.membres_de_famille_residents
						}))
				datas.append('contact_addresses', JSON.stringify({
							"address": {
								"address": this.individu.adresse.adresse,
								"city": this.individu.adresse.ville,
								"state": this.individu.adresse.etat,
								"postcode": this.individu.adresse.code_postal
							},
							"postbox": "",
							"phone": this.individu.telephone_fixe,
							"mobile": this.individu.telephone_portable,
							"email": this.individu.email,
							"contact_consent": parseInt(this.individu.consentement_de_contact)
						}))
				datas.append('electoral_situation', JSON.stringify({
							"registration_electoral_roll": parseInt(this.individu.inscription_sur_liste_electorale),
							"registration_in_ci": {
								"commune": this.individu.inscription_en_ci.commune,
								"department": this.individu.inscription_en_ci.departement
							},
							"desired_polling_place": this.individu.lieu_de_vote_souhaite
						}))
				datas.append('socio_professional_situation', JSON.stringify({
							"profession": this.individu.profession,
							"employer": this.individu.employeur,
							"address": {
								"address": this.individu.adresse_professionnelle.adresse,
								"city": this.individu.adresse_professionnelle.ville,
								"state": this.individu.adresse_professionnelle.etat,
								"postcode": this.individu.adresse_professionnelle.code_postal
							},
							"professional_phone": this.individu.telephone_professionnel,
							"fax": this.individu.telecopie_professionnelle,
							"email": this.individu.email_professionnelle
						}))
				datas.append('people_to_prevent', JSON.stringify({
							"usa": {
								"lastname": this.individu.personnes_a_prevenir_usa.nom,
								"firstname": this.individu.personnes_a_prevenir_usa.prenoms,
								"family relationship": this.individu.personnes_a_prevenir_usa.lien_de_parente,
								"address": {
									"address": this.individu.personnes_a_prevenir_usa.adresse.adresse,
									"city": this.individu.personnes_a_prevenir_usa.adresse.ville,
									"state": this.individu.personnes_a_prevenir_usa.adresse.etat,
									"postcode": this.individu.personnes_a_prevenir_usa.adresse.code_postal
								},
								"phone": this.individu.personnes_a_prevenir_usa.telephone,
								"fax": this.individu.personnes_a_prevenir_usa.telecopie,
								"email": this.individu.personnes_a_prevenir_usa.email
							},
							"ci": {
								"lastname": this.individu.personnes_a_prevenir_ci.nom,
								"firstname": this.individu.personnes_a_prevenir_ci.prenoms,
								"family relationship": this.individu.personnes_a_prevenir_ci.lien_de_parente,
								"address": {
									"address": this.individu.personnes_a_prevenir_ci.adresse.adresse,
									"city": this.individu.personnes_a_prevenir_ci.adresse.ville,
									"postcode": this.individu.personnes_a_prevenir_ci.adresse.code_postal
								},
								"phone": this.individu.personnes_a_prevenir_ci.telephone,
								"fax": this.individu.personnes_a_prevenir_ci.telecopie,
								"email": this.individu.personnes_a_prevenir_ci.email
							}
						}))
				
				axios({
					method: 'POST',
					headers: this.$root.authHeaderFormData(),
					url: requestUrl,
					data: datas,
					responseType: 'json'
				})
				.then(r => {    
					if (r.data.state == 'success') {
						//let w = (this.uid == null) ? 'créé' : 'modifié'
						this.$swal("C'est fait", "L'enregistrement a bien été effectué.", 'success')
						this.resetForm()
						if(!this.uid)
							document.location.href = this.$root.redirectUri +'/immatriculation/documents/'+ r.data.person
						else
							document.location.href = this.$root.redirectUri +'/immatriculation/documents/'+ this.uid

					}else{
						this.$swal('Oups !', r.data.message, 'error')
					}
				})
				.catch(function (e) {
					console.log(e)
					console.log(vm)
					//vm.$root.relogin()
				})
				axios.interceptors.request.eject(this.interceptor)
				
			}
		},
		getPerson () {
			let vm = this
			this.interceptor = axios.interceptors.request.use((config) => {
				this.$swal('Veuillez patienter', 'Chargement des données...', 'info')
					return config
			}, (error) => {
				return Promise.reject(error)
			})

			axios({
				method: 'GET',
				headers: this.$root.authHeader(),
				url: 'registration/'+this.uid,
				responseType: 'json'
			})
			.then(r => {    
				if (r.data.state == 'success') {
					let p = r.data.person

					this.individu = {
						'ia': (p.previous_registration.ia == false) ? 0 : 1,
						'cgci': p.previous_registration.cgci,
						'genre': p.gender,
						'nom_de_famille': p.lastname,
						'nom_usage': p.usename,
						'prenoms': p.firstname,
						'date_naissance': p.birthday,
						'lieu_naissance': p.birthplace,
						'commune_sp_naissance': p.town_sp_birth,
						'pays_naissance': p.country_of_birth,
						'couleur_yeux': p.eye_colour,
						'taille': p.height,
						'passeport': {
							'numero': p.nationality.passeport.number,
							'date_delivrance': p.nationality.passeport.date_issue,
							'date_expiration': p.nationality.passeport.expiration_date,
							'lieu_delivrance': p.nationality.passeport.place_delivery
						},
						'carte_identite': {
							'numero': p.nationality.identity_card.number,
							'date_delivrance': p.nationality.identity_card.date_issue,
							'date_expiration': p.nationality.identity_card.expiration_date,
							'lieu_delivrance': p.nationality.identity_card.place_delivery
						},
						'certificat_nationalite_ivoirienne': {
							'numero': p.nationality.ivorian_nationality_certificate.number,
							'date_delivrance': p.nationality.ivorian_nationality_certificate.date_issue,
							'lieu_delivrance': p.nationality.ivorian_nationality_certificate.place_delivery
						},
						'pere': {
							'nom': p.parents.father.lastname,
							'prenoms': p.parents.father.firstname,
							'date_naissance': p.parents.father.birthday,
							'ville': p.parents.father.city,
							'departement': p.parents.father.department,
							'pays': p.parents.father.country,
							'nationalite': p.parents.father.nationality
						},
						'mere': {
							'nom': p.parents.mother.lastname,
							'prenoms': p.parents.mother.firstname,
							'date_naissance': p.parents.mother.birthday,
							'ville': p.parents.mother.city,
							'departement': p.parents.mother.department,
							'pays': p.parents.mother.country,
							'nationalite': p.parents.mother.nationality
						},
						'lien_familial': {
							'situation_matrimoniale': p.family_status.family_link.marital_status,
							'date': p.family_status.family_link.date,
							'lieu': p.family_status.family_link.place
						},
						'membres_de_famille_residents': [
							{
								'nom': "",
								'prenoms': "",
								'date_naissance': "",
								'lieu_naissance': ""
							}
						],
						'adresse': {
							'adresse': p.contact_addresses.address.address,
							'ville': p.contact_addresses.address.city,
							'code_postal': p.contact_addresses.address.postcode,
							'etat': p.contact_addresses.address.state
						},
						'consentement_de_contact': (p.contact_addresses.contact_consent == false) ? 0 : 1,
						'telephone_fixe': p.contact_addresses.phone,
						'telephone_portable': p.contact_addresses.mobile,
						'email': p.contact_addresses.email,
						'inscription_sur_liste_electorale': (p.electoral_situation.registration_electoral_roll == false) ? 0 : 1,
						'inscription_en_ci': {
							'commune': p.electoral_situation.registration_in_ci.commune,
							'departement': p.electoral_situation.registration_in_ci.department,
						},
						'lieu_de_vote_souhaite': p.electoral_situation.desired_polling_place,
						'profession': p.socio_professional_situation.profession,
						'employeur': p.socio_professional_situation.employer,
						'adresse_professionnelle': {
							'adresse': p.socio_professional_situation.address.address,
							'ville': p.socio_professional_situation.address.city,
							'code_postal': p.socio_professional_situation.address.postcode,
							'etat': p.socio_professional_situation.address.state
						},
						'telephone_professionnel': p.socio_professional_situation.professional_phone,
						'telecopie_professionnelle': p.socio_professional_situation.fax,
						'email_professionnelle': p.socio_professional_situation.email,
						'personnes_a_prevenir_usa': {
							'nom': p.people_to_prevent.usa.lastname,
							'prenoms': p.people_to_prevent.usa.firstname,
							'lien_de_parente': p.people_to_prevent.usa['family relationship'],
							'adresse': {
								'adresse': p.people_to_prevent.usa.address.address,
								'ville': p.people_to_prevent.usa.address.city,
								'code_postal': p.people_to_prevent.usa.address.postcode,
								'etat': p.people_to_prevent.usa.address.state
							},
							'telephone': p.people_to_prevent.usa.phone,
							'telecopie': p.people_to_prevent.usa.fax,
							'email': p.people_to_prevent.usa.email
						},
						'personnes_a_prevenir_ci': {
							'nom': p.people_to_prevent.ci.lastname,
							'prenoms': p.people_to_prevent.ci.firstname,
							'lien_de_parente': p.people_to_prevent.ci['family relationship'],
							'adresse': {
								'adresse': p.people_to_prevent.ci.address.address,
								'ville': p.people_to_prevent.ci.address.city,
								'code_postal': p.people_to_prevent.ci.address.postcode
							},
							'telephone': p.people_to_prevent.ci.phone,
							'telecopie': p.people_to_prevent.ci.fax,
							'email': p.people_to_prevent.ci.email
						}
					}

					this.photo = {
						src: p.photo,
						type: null
					}

					this.signature = {
						src: p.signature,
						type: null
					}

					this.photoPreview = p.photo,
					this.signaturePreview = p.signature,

					//console.log(this.person)
					this.$swal.close()

					this.getDocuments()

				}else{
					this.$swal('Oups !', r.data.message, 'error')
				}
			})
			.catch(function (e) {
				console.log(e)
				console.log(vm)
				//vm.$root.relogin()
			})
			axios.interceptors.request.eject(this.interceptor)
		}
	},
	created () {
		//this.getRoles()
		if(this.$route.params.id){
			this.uid = this.$route.params.id
			this.getPerson()
		}

		this.updateLocalIndividu()

	},
	destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
}
</script>
